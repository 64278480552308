
@import '../../../../assets/scss/variables.scss';

.news-tab {
  .create-file-upload {
    border-radius: 4px;
    border: 1px dashed #c6c8d1;
    background: $bg-20;
    padding: 6px 7px;

    .p-fileupload-buttonbar {
      padding: 0rem;
      border: none;
      button{
        display: none;
      }
    }

    .p-fileupload-content {
      display: none;
    }

    .p-fileupload .p-fileupload-buttonbar .p-button {
      margin: 0px;
    }

    .p-fileupload-choose {
      padding: 4px 12px;
      background: $white;
      border: 1px solid #eaecf0;
      white-space: nowrap;

      .p-button-label {
        font-size: 13px;
        font-weight: 500;
        color: $text-40;
      }

      svg {
        color: $text-40;
        width: 10px;
        height: 10px;
      }
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: #9295a3;
    }
  }

  .uploaded-file-container {
    padding: 4px;

    .uploaded-files {
      padding: 4px 10px 4px 8px;
      border-radius: 4px;
      border: 1px solid #eaecf0;
      color: #151518;
      font-size: 13px;
      font-weight: 500;
      width: fit-content;

      .pi.pi-trash {
        margin-left: 6px;
        color: #9295a3;
        font-size: 12px;
        cursor: pointer;
        &:hover{
          color: $danger-20;
        }
      }
    }
    &.attachments-upload{
      padding: 0px;
      .uploaded-files{
        display: inline-block;
        margin: 8px 8px 0px 0px;
      }
    }
  }

  .action-buttons {
    border-radius: 4px;
    background: $primary-60;
    padding: 10px 18px;
    border: none;

    .p-button-label {
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      background: $black;
    }
  }
  .error{
    color:"red"
  }
  .chips-field{
    ul{
        margin: 0px !important;
        gap: 5px 0px;
        &.p-chips-multiple-container{
            width: 100%;
            border-radius: 6px;
            border: 1px solid $blue-bg-40;
            background: $blue-bg-20;
            font-size: 18px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            padding: 7px 10px !important;
            height: 44px;
            overflow-y: auto;
            .p-chips-input-token{
                padding: 0px;
            }
            .p-chips-token{
                border-radius: 34px;
                border: 1px solid #CFD8E3;
                background:$white;
                padding: 1px 10px 2px 12px;
                .p-chips-token-label{
                    font-family: $font-base;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 160%;
                    color: $text-40;
                }
                .p-chips-token-icon{
                    opacity: 0.6;
                    position: relative;
                    top: 1px;
                    left: 5px;
                    margin-left: 5px;
                    &:hover{
                        color: $primary-70;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

}
