
.red-color {
    background-color: #F78C8C
    ;
  }
  
  .green-color {
    background-color: #80BA06
    ;
  }
  
  .blue-color {
    background-color: #529FC0   ;
  }
  
  .purple-color {
    background-color: #C764BD
    ;
  }
  
  .yellow-color {
    background-color:#CBCF0E;
  }
  .black-color{
    background-color: #68B45C
    ;
  }