@import '../../../../assets/scss/variables';

.form-input-row {
    display: flex;
    justify-content: space-between;
 
   margin-top: 20px;
  }
  
  .form-input-row .col-6 {
    flex: 1;
  }
  
  .form-input-row .form-input {
    width: 100%;
  }
  .new-list-section{
    .edit-details{
      .attachment-item{
        .attachment-button{
            background: $bg-20;
            padding: 8px 12px 8px 14px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            display: flex;
            margin-top: 0px;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            .p-button-icon{
                color: $text-40;
                font-size: 12px;
            }
            &:hover{
                background: $text-40;
                .p-button-label{
                    color: $white;
                }
                .p-button-icon{
                    color: $white;
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
        .delete-attachment-icon{
          font-size: 14px;
          margin-left: 6px;
          cursor: pointer;
          margin-right: 15px;
          &:hover{
            color: $danger-20;
          }
        }
      }
    }
  }
  // .attachment-button {
  //   display: flex;
  //   align-items: center;
  //   padding: 0.5em 1em;
  //   border-radius: 5px;
  //   border: 1px solid #e0e0e0;
  //   background-color: #f9f9f9;
  //  color: black;
  //   cursor: pointer;
  //   margin-right: 1em; 
  //   margin-bottom: 0.5em; 
  // }
  