@import '../../assets/scss/variables';

.help-page-section{
    padding: 25px 70px;
    height: calc(100% - 204px);
    min-height: calc(100vh - 204px);
    position: relative;
    &:before{
        content: "";
        position: absolute;
        width: 100%;
        min-height: 190px;
        background: $border-gray;
        left: 0px;
        right: 0px;
        top: 0px;
        z-index: -1;
    }
    .help-wrapper{
        h1{
            font-size: 20px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            margin: 0px;
        }
        .help-tabs-section{
            margin-top: 1.5rem;
            .vertical-tabs{
                display: flex;
                flex-direction: row-reverse;
                .p-tabview-nav-container{
                    min-width: 300px;
                    .p-tabview-nav-content{
                        position: sticky;
                        top: 20px;
                        .p-tabview-nav{
                            display: block;
                            border: none;
                            background: none;
                            li{
                                .p-tabview-nav-link{
                                    border: none;
                                    padding: 6px 16px;
                                    border-radius: 4px;
                                    background: transparent;
                                    margin-bottom: 10px;
                                    border: 1px solid transparent;
                                    display: inline-block;
                                    .p-tabview-title{
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: $text-30;
                                        white-space: break-spaces;
                                        line-height: 17px;
                                        border: 1px solid transparent;
                                    }
                                    &:focus{
                                        box-shadow:none;
                                    }
                                    &:hover{
                                        background: $bg-30;
                                        border-radius: 4px;
                                        border: 1px solid $bg-40;
                                        .p-tabview-title{
                                            color: $text-40; 
                                        }
                                    }
                                }
                                &.p-highlight{
                                    .p-tabview-nav-link{
                                        background: $bg-30;
                                        border: 1px solid $bg-40;
                                        .p-tabview-title{
                                            color: $text-40;
                                            font-weight: 600;
                                        }
                                    }
                                }
                                &.p-tabview-ink-bar{
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .p-tabview-panels{
                    padding: 0px;
                    background: none;
                    margin-right: 25px;
                    width: 100%;
                }
            }
        }
        .help-content{
            background: $white;
            padding: 30px;
            border-radius: 8px;
            border: 1px solid $bg-40;
            h2{
                font-size: 14px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 22px;
            }
            p{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
                margin: 15px 0px 0px;
                line-height: 21px;
                a{
                    color: $blue-70;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            ul{
                margin: 0px;
                padding: 1px 0px 0px 30px;
                li{
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                    margin: 6px 0px;
                    line-height: 160%;
                    a{
                        color: $blue-70;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .copy-btn{
                background: $white;
                padding: 6px 10px 6px 8px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                margin-left: 15px;
                .p-button-label{
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                }
                .p-button-icon{
                    color: $text-40;
                    font-size: 12px;
                }
                &:hover{
                    background: $text-40;
                    .p-button-label{
                        color: $white;
                    }
                    .p-button-icon{
                        color: $white;
                    }
                }
                &:focus{
                    box-shadow: none;
                }
            }
            &.contact-info{
                min-height: 250px;
                p{
                    span{
                        color: $text-30;
                    }
                    &:first-child{
                        margin: 0px;
                    }
                }
            }
            .video-wrapper{
                margin-top: 1.5rem;
                video{
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid $border-gray;
                }
            }
            .login-images{
                .p-image{
                    display: inline-block;
                    padding: 2rem 0.8rem;
                    vertical-align: top;
                    line-height: 0px;
                    img{
                        width: 100%;
                        max-width: 350px;
                    }
                }
            }
        }
    }
}

@media (min-width:1200px) and (max-width:1350px){
    .help-page-section .help-wrapper .help-content .login-images .p-image img{
        max-width: 300px;
    }
}