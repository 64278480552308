@import '../../../../assets/scss/variables.scss';

.news-categories-section{
    h1{
        font-size: 24px;
        &:first-letter {
            text-transform: uppercase;
          }
    }
    .create-button{
        background: $blue-70;
        border-radius: 4px;
        border: none;
        padding: 8px 14px 8px 12px;
        .p-button-label{
            color: $white;
            font-size: 13px;
            font-weight: 500;
        }
        .p-button-icon{
            color: $white;
            font-size: 12px;
        }
        &:hover{
            background: $text-40;
        }
    }
    .categories-list{
        margin-top: 30px;
        .info-box{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 18px;
            border-radius: 8px;
            border: 1px solid $home-box-border;
            background: $white;
            padding: 18px 20px;
            color: $text-40;
            h4{
                margin: 0px;
                padding: 0px;
                color: $text-40;
                font-size: 14px;
                font-weight: 500;
                .pi{
                    font-size: 13px;
                    margin-right: 5px;
                }
            }
            p{
                margin: 12px 0px 0px;
                color: $text-30;
                font-size: 14px;
                font-weight: 400;
                line-height: 23px;
            }
            h2{
                font-size: 20px;
                font-weight: 600;
                color: $text-40;
                font-family: $font-secondary;
                margin:0px;
            }
            .info-span{
                color: $text-30;
                font-size: 13px;
                font-weight: 400;
                b{
                    color: $danger-20;
                    font-size: 14px;
                    font-weight: 500;
                    .pi{
                        font-size: 12px;
                    }
                }
            }
            .multi-select-dropdown{
                width: 100%;
                max-width: 175px;
                min-width: 175px;
                margin-right: 10px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                background: $bg-20;
                .p-multiselect-label{
                    padding: 7px 10px 7px 12px;
                    color: $text-40;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
            .export-button{
                padding: 8px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                background: $bg-20;
                width: 28px;
                height: 28px;
                .p-button-icon{
                    color: $text-40;
                }
            }
            .header{
                margin-bottom: 25px;
                span{
                    color: $text-30;
                    font-size: 13px;
                    font-weight: 400; 
                }
            }
            .horizontal-bar-chart{
                height: 75px;
            }
            .chart-info{
                padding: 0px 6px;
                min-height: 188px;
                ul{
                    margin: 0px 0px 19px;
                    padding: 0px;
                    li{
                        font-size: 12px;
                        font-weight: 500;
                        b{
                            font-weight: 600;
                        }
                        .indicator{
                            border-radius: 2px;
                            background: #B9BC19;
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            margin-right: 3px;
                            &.not-suspicious{
                                background: #FC702E;
                            }
                            &.not-offered{
                                background: #94C11F;
                            }
                        }
                    }
                }
            }
            .chartbar-details-view{
                .p-chart{
                    canvas{
                        width: 100% !important;
                        height: 350px !important;
                    }
                }
            }
        }
    }
}