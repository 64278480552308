@import '../../../assets/scss/variables.scss';

.alerts-tab{
    &.news-updates-section{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        background: $blue-bg-30;
                        border: 1px solid $blue-bg-40;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                background: $blue-bg-30;
                            }
                        }
                    }
                }
            }
        }
        .news-tab {
            padding: 0px;
            min-height: auto;
            &.general-news-tab{
                margin-top: 2rem;
                .info-button{
                    padding: 8px;
                    border-radius: 4px;
                    border: 1px solid $bg-40;
                    background: $white;
                    width: 32px;
                    height: 32px;
                    margin-left: 10px;
                    margin-right: 30px;
                    .p-button-icon{
                        color: $text-40;
                    }
                    &:hover{
                        background: $blue-70;
                        .p-button-icon{
                            color: $white;
                        }
                    }
                    &.categories-button{
                        background: $white;
                        width: auto;
                        padding: 8px 10px;
                        margin-right: 0px;
                        .p-button-label{
                            color: $text-40;
                            font-size: 13px;
                            font-weight: 500;
                        }
                        .p-button-icon{
                            color: $text-40;
                            font-size: 12px;
                        }
                    }
                }
                .pagination{
                    background: $blue-bg-30;
                    border: 1px solid $blue-bg-40;
                    border-width: 0px 1px 1px 1px;
                }
                .filter-section{
                    top: 8px;
                }
            }
        }
    }
}
.general-news-section{
    width: 100%;
    max-width: 875px;
    margin: 0 auto;
    padding: 40px 0px;
    .list-wrapper{
        border-radius: 8px !important;
        h2{
            font-size: 20px;
            font-weight: 500;
            font-family: $font-secondary;
            margin: 0px 0px 25px;
            line-height: 32px;
        }
    }
    .action-buttons{
        background: $white;
        border-radius: 4px;
        border: 1px solid #D9DFE8;
        padding: 7px 14px 7px 12px;
        .p-button-label{
            color: $text-40;
            font-size: 13px;
            font-weight: 500;
        }
        .p-button-icon{
            color: $text-40;
            font-size: 12px;
        }
        &:hover{
            background: $blue-70;
            .p-button-icon, .p-button-label{
                color: $white;
            }
        }
        &.save-button{
            background: $blue-70;
            border: 1px solid $blue-70;
            .p-button-icon, .p-button-label{
                color: $white;
            }
            &:hover{
                background: $white;
                border: 1px solid #D9DFE8;
                .p-button-icon, .p-button-label{
                    color: $text-40;
                }
            }
        }
    }
    h1{
        margin: 0px !important;
        font-family: $font-secondary;
        font-size: 24px !important;
    }
    .edit-details{
        .card{
            label, .label{
                color: $text-40;
                font-size: 14px;
                font-weight: 500; 
            }
            .input-field{
                border-radius: 6px;
                border: 1px solid $blue-bg-40;
                background: $blue-bg-20;
                font-size: 18px;
                font-weight: 500;
                color: $text-40;
                font-family: $font-secondary;
            }
            .edit-file-upload{
                position: relative;
                .p-fileupload-choose{
                    width: 100%;
                    min-height: 100%;
                    background: none;
                    position: absolute;
                    border: none;
                    .p-button-label{
                        display: none;
                    }
                    .p-button-icon{
                        display: none;
                    }
                    &.p-fileupload-choose-selected{
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        .p-button-icon{
                            display: block;
                        }
                    }
                }
                .new-edit-image{
                    img{
                        width: 100%;
                    }
                }
                .cover-image-delete{
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                    background: $danger-20;
                    color: $white;
                    display: flex;
                    width: 25px;
                    height: 25px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    top: 10px;
                    padding: 16px;
                    font-size: 15px;
                    z-index: 1;
                    &:hover{
                        background: $text-40;
                    }
                    &.edit{
                        right: 50px;
                        background: $text-40;
                        font-size: 13px;
                        &:hover{
                            background: $blue-70;
                        }
                    }
                }
            }
            .create-file-upload{
                border-radius: 4px;
                border: 1px dashed #C6C8D1;
                background: $bg-20;
                padding: 6px 7px;
                .p-fileupload-choose{
                    padding: 4px 12px;
                    background: $white;
                    border: 1px solid #EAECF0;
                    .p-button-label{
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                    }
                    svg{
                        color: $text-40;
                        width: 10px;
                        height: 10px;
                    }
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color:#9295A3;
                }
            }
            .content-editor{
                .p-editor-container{
                    .p-editor-toolbar{
                        background: $blue-bg-20;
                    }
                    .p-editor-content{
                        .ql-editor{
                            background: $blue-bg-20; 
                        }
                    }
                }
            }
            .form-input{
                .p-dropdown{
                    background: $blue-bg-20;
                    border: 1px solid $blue-bg-40;
                    .p-dropdown-label{
                        font-size: 15px;
                        font-weight: 500;
                        color: $text-40;
                    }
                }
            }
            .status-box{
                background: $blue-bg-20;
                padding: 10px 14px;
                border-radius: 6px;
                border: 1px solid $blue-bg-40;
                .p-checkbox{
                    width: 18px;
                    height: 18px;
                    .p-checkbox-box{
                        width: 18px;
                        height: 18px;
                        background: #F3F3F3;
                        &:hover{
                            border-color: $text-40;
                        }
                        &.p-focus{
                            box-shadow: none;
                        }
                    }
                    &.p-checkbox-checked{
                        .p-checkbox-box{
                            background:$text-40;
                            border-color: $text-40;
                        }
                    }
                }
                label{
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-40;
                }
            }
            .p-multiselect{
                background: $blue-bg-20;
                border: 1px solid $blue-bg-40;
                .p-multiselect-label{
                    padding: 8px 14px;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-40;
                }
            }
        }
    }
}
.p-multiselect-panel{
    .p-inputtext{
        padding: 8px 14px;
    }
}
.list-wrapper{
    .hr-line:last-of-type{
        display: none;
    }
}
.news-search-bar{
    .search-box{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        padding: 8px 14px 8px 40px;
        font-size: 13px;
        font-weight: 400;
        color: $text-30;
        height: 36px;
    }
}
.news-date-calendar{
    border: 1px solid #d1d5db;
    border-radius: 6px;
    .p-datepicker-trigger{
        background: $white;
        padding: 7px 6px 7px 10px;
        width: auto;
        color: $text-40;
        border: none;
        border-right: 0px;
        &:focus{
            box-shadow: none;
        }
        &:hover{
            background: none;
            svg{
                color: $text-40;
            }
        }
    }
    .p-inputtext{
        padding: 7px 14px 7px 6px;
        font-size: 13px;
        font-weight: 500;
        color: $text-40;
        border: 0px;
        &:focus{
            box-shadow: none;
        }
    }
    &:hover{
        border-color: $text-20;
    }
}
.news-calendar, .news-search {
    height: 33px !important;
    border-radius: 4px !important;
}
.custom-clear-calendar {
    position: relative;
    .custom-clear {
      position: absolute;
      right: 10px;
      top: 8px;
      .pi {
        font-size: 16px;
        cursor: pointer;
      }
    }
}
