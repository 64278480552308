@import '../../assets/scss/variables.scss';

// **** External Links Tab CSS **** //
.external-links-tab{
    font-family: $font-base;
    height: calc(100% - 275px);
    min-height: calc(100vh - 275px);
    padding: 25px 70px;
    h1{
        font-size: 20px;
        font-weight: 500;
        color: $text-40;
        font-family: $font-secondary;
        margin: 0px;
    }
    .links-left-section{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        padding: 24px 30px 24px 24px;
        .link-container{
            border-bottom: 1px solid $bg-40;
            padding: 20px 0px;
            &:first-child
            {
                padding-top: 0px;
                padding-bottom: 20px;
            }  
            &:last-child {
                border: none;
            }
        }
        .sports-icon{
            img{
                height: 40px;
                width: 40px;
            }
        }
        .icon{
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $white;
            display: flex;
            width: 66px;
            height: 60px;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            .pi{
                font-size: 22px;
            }
        }
        h4{
            margin: 0px 0px 5px;
            padding: 0px;
            font-size: 15px;
            font-weight: 500;
            color: $text-40;
            line-height: 23px;
        }
        p{
            margin: 0px;
            padding: 0px;
            font-size: 14px;
            font-weight: 400;
            color: $text-30;
            line-height: 22px; 
        }
        .link-info{
            .links {
                width: fit-content;
                &:hover{
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    color:$text-20;
                    .pi {
                        color: $text-40;
                    }
                }
            }
            .pi {
                font-size: 12px;
                color: $text-20;
            }
        }
    }
    &.member-links{
        .link-box{
            border: 1px solid #D9BAAC;
            background: rgba(245, 225, 216, 0.33);
        }
    }
}
