@import "../../../assets/scss/variables.scss";

// **** Regulator alerts CSS **** //

.regulator-alerts {
  font-family: $font-base;
  height: calc(100% - 285px);
  min-height: calc(100vh - 285px);
  padding: 25px 70px;
  background: #f6f6f6;
  h1 {
    font-size: 24px;
    font-weight: 500;
    color: $text-40;
    font-family: $font-secondary;
    margin: 0px;
  }
  .search-bar {
    width: 100%;
    margin-top: 1rem;
    .p-input-icon-left > i {
      z-index: 1;
    }
    .search-box {
      border-radius: 8px;
      border: 1px solid $re-bg-40;
      background: $white;
      padding: 12px 14px 12px 40px;
      font-size: 14px;
      font-weight: 400;
      color: $text-30;
    }
    .date-calendar {
      border: 1px solid $re-bg-40;
      border-radius: 6px;
      .p-datepicker-trigger {
        background: $white;
        padding: 7px 6px 7px 12px;
        width: auto;
        color: $text-40;
        border: none;
        border-right: 0px;
        &:focus {
          box-shadow: none;
        }
      }
      .p-inputtext {
        padding: 12px 14px 12px 5px;
        font-size: 14px;
        font-weight: 500;
        color: $text-40;
        border: 0px;
        width: auto;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          color: #151518;
        }
      }
      &:hover {
        border-color: $text-20;
      }
    }
    .custom-clear-calendar {
      position: relative;
      .custom-clear {
        position: absolute;
        right: 10px;
        top: 14px;
        .pi {
          font-size: 16px;
          color: #6c757d;
          cursor: pointer;
        }
      }
    }
    .country-dropdown {
      border-radius: 4px;
      border: 1px solid $re-bg-40;
      background: $white;
      .p-dropdown-label {
        padding: 12px 18px 12px 12px;
        font-size: 14px;
        font-weight: 500;
        color: $text-40;
      }
    }
  }
  .worldmap-section {
    .worldmap__figure-container {
      background-color: transparent !important;
      text-align: center;
      svg {
        overflow: visible;
      }
    }
  }
}

.counts-dialog {
  .dialog-header {
    .search-box {
      border-radius: 8px;
      border: 1px solid $re-bg-40;
      background: $white;
      padding: 12px 14px 12px 40px;
      font-size: 14px;
      font-weight: 400;
      color: $text-30;
    }
    .list {
      margin-top: 25px;
      padding: 0px 5px;
      ul {
        margin: 0px 0px 18px;
        padding: 0px;
        li {
          font-size: 15px;
          font-weight: 500;
          color: $text-40;
          span {
            font-size: 12px;
            font-weight: 400;
            color: $text-30;
          }
        }
        &:last-child {
          margin: 0px;
        }
      }
    }
  }
  .p-dialog-content {
    border-top: 1px solid $blue-bg-40;
    border-bottom: 1px solid $blue-bg-40;
    min-height: 200px;
    max-height: 300px;
  }
  .p-dropdown-panel {
    .p-dropdown-header {
      padding: 12px 14px;
      .p-dropdown-filter {
        padding: 8px 15px;
      }
    }
  }
  .p-dialog-footer {
    .dialog-footer-sec {
      padding: 1rem;
    }
    &:has(.dialog-footer-sec) {
      padding: 0px !important;
    }
  }
}
