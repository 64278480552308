@import "../../assets/scss/variables.scss";

// **** Reports page CSS **** //
.reports-section {
  .new-list-section {
    position: relative;
    h1 {
      font-size: 24px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .download-button {
      padding: 8px 12px;
      border-radius: 4px;
      border: none;
      background: $blue-70;
      height: fit-content;
      &.regulator {
        background: $re-green-70;
      }
      .p-button-label {
        font-size: 13px;
        font-weight: 500;
      }
      .p-button-icon {
        font-size: 13px;
      }
      &:hover {
        background: $text-40;
      }
    }
    .content p:first-letter {
      text-transform: uppercase;
    }
    .list-wrapper {
      .news-list {
        .report-cover{
            width: 230px;
        }
        .relative {
          .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            background: rgba(0, 0, 0, 0.5);
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
              color: $white;
              font-size: 21px;
              font-weight: 600;
              margin: 0px;
              width: 100%;
              max-width: 150px;
              line-height: 30px;
            }
          }
        }
        h3.title {
          font-family: $font-secondary;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          &:first-letter {
            text-transform: uppercase;
          }
        }
        p {
          margin: 0px;
        }
        .description {
            * {
                margin: 0px!important;
                padding: 0px!important;
              }         
            ol,
            ul {
                list-style-position: inside!important;
            }   
            margin-top: 10px;
            font-size: 13px !important;
            color: $text-30 !important;
            font-weight: 500 !important;
            line-height: 22px!important;
          p {
            font-size: 13px !important;
            &:first-letter {
              text-transform: uppercase;
            }
          }
          .desc-content > *:last-child::after {
            content: '...see more';
            color: $text-40;
            }
        }
        .vertical-gray {
          width: 1px;
          height: 14px;
          border: 1px solid #dadada;
          border-radius: 4px;
        }
        .pi.pi-calendar {
          margin-right: 2px;
        }
        .info-button{
          padding: 8px;
          border-radius: 4px;
          border: 1px solid $bg-40;
          background: $white;
          width: 32px;
          height: 32px;
          .p-button-icon{
              color: $text-40;
          }
          &:hover{
              background: $blue-70;
              .p-button-icon{
                  color: $white;
              }
          }
        }
      }
    }
    .status-filter-section {
      .search-filters-button {
        padding: 7px 14px 7px 12px;
        border-radius: 6px;
        border: 1px solid $border-gray;
        background: $white;
        .label {
          font-size: 13px;
          font-weight: 500;
          color: $text-40;
          margin-left: 5px;
        }
        .p-image {
          line-height: 0px;
          img {
            position: relative;
            top: 1px;
          }
        }
        &:hover {
          background: $text-40;
          border: 1px solid $text-40;
          .label {
            color: $white;
          }
          .p-image {
            img {
              filter: invert(1);
            }
          }
        }
      }
      .drop-down-button {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid $bg-40;
        background: $white;
        width: 32px;
        height: 32px;
        .p-button-icon {
          color: $text-40;
          font-size: 15px;
          position: relative;
          top: 1px;
        }
      }
      .reports-drop-down {
        height: 32px;
        font-weight: 500;
        border: 1px solid $re-bg-40;
        .p-inputtext {
          padding: 8px 8px 8px 10px;
          font-size: 13px;
          color: $text-40;
          line-height: 14px;
          &.p-placeholder {
            color: #6c757d;
          }
        }
        .p-dropdown-trigger {
          width: 2rem;
          .p-icon {
            width: 12px;
            height: 12px;
            color: $text-40;
            margin-bottom: -3px;
          }
          
        }
        .p-dropdown-clear-icon{
          position: static;
          margin-top: 9px;
          width: 12px;
          height: 12px;
      }
      }
      .custom-clear-calendar{
        position: relative;
        .date-calendar {
          height: 32px;
          border: 1px solid $re-bg-40;
          .p-inputtext {
            padding: 8px 14px 8px 6px;
          }
          .p-datepicker-trigger {
            .p-icon {
              width: 12px;
              height: 12px;
              color: $text-40;
            }
          }
        }
        .custom-clear{
          position: absolute;
          right: 10px;
          top: 6px;
          .pi {
              font-size: 12px!important;
              color: #6c757d;
              cursor: pointer;
          }
        }
      }
    }
    .filter-applied-section {
      border-radius: 6px;
      border: 1px solid $bg-40;
      background: $bg-30;
      width: 100%;
      padding: 14px 20px 14px 20px;
      margin: 20px 0px;
      hr {
        border-top: 1px solid $bg-40;
        margin: 15px 0px;
      }
      p {
        margin: 0px;
        font-size: 12px;
        font-weight: 400;
        color: $text-40;
      }
      .filters-list {
        font-size: 12px;
        font-weight: 400;
        color: $text-60;
        margin-top: 5px;
        display: block;
        ul {
          margin: 0px;
          padding: 0px;
          li {
            list-style: none;
            display: inline-block;
            span {
              padding: 6px 10px;
              border-radius: 4px;
              border: 1px solid $bg-40;
              background: $white;
              font-size: 12px;
              font-weight: 500;
              margin-left: 10px;
              display: inline-block;
              .pi {
                font-size: 12px;
                color: $text-30;
                position: relative;
                top: 1px;
                margin-left: 3px;
              }
            }
          }
          &.other-ul {
            li {
              margin-left: 8px;
              font-size: 12px;
              font-weight: 500;
              color: $text-60;
              margin-bottom: 8px;
              &:first-child {
                margin-left: 0px;
                font-weight: 400;
              }
              span {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.searchDescription{
  border-radius: 8px;
  border: 1px solid $bg-40;
  background: $white;
  padding: 8px 14px 8px 40px;
  font-size: 13px;
  font-weight: 400;
  color: $text-30;
  height: 33px;
  &:focus{
    border-color: $bg-40 !important;
  }
}
.report-attachments{
  button{
      margin-top: 0px !important;
      margin-bottom: 0px !important;
  }
}
