@import '../../../assets/scss/variables.scss';

// **** Home Tab CSS **** //
.analyst-home-section{
    width: 100%;
    margin: 0 auto;
    padding: 35px 70px;
    height: calc(100% - 188px);
    min-height: calc(100vh - 188px);
    .info-box{
        border-radius: 8px;
        border: 1px solid $home-box-border;
        background: $white;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $text-40;
        h2{
            font-size: 30px;
            font-weight: 600;
            margin: 0px; 
            line-height: 45px;
            font-family: $font-secondary;
            .pi{
                font-weight: 600;
            }
        }
        p{
            margin: 8px 0px 0px;
            font-size: 20px;
            font-weight: 500;
            font-family: $font-secondary;
            .link{
                margin-left: 10px;
                &:active{
                    pointer-events: none;
                }
                .pi{
                    color: $text-20;
                    font-size: 14px;
                    padding-bottom: 2px;
                    border-bottom: 2px solid;
                    &:hover{
                        color: $text-40;
                    }
                }
            }
        }
        &:hover{
            border: 1px solid $blue-70;
            opacity: 0.7;
        }
    }
    .all-alerts-section{
        padding: 0px;
        margin-top: 1.5rem;
        height: auto;
        min-height: auto;
        .alerts-tab{
            margin-top: 5px;
            .status-filter-section{
                top: -53px;
            }
        }
    }
}
