@import '../../../../assets/scss/variables.scss';

.general-news-section{
    width: 100%;
    max-width: 875px;
    margin: 0 auto;
    padding: 40px 0px;
    .list-wrapper{
        border-radius: 8px !important;
        h2{
            font-size: 20px;
            font-weight: 500;
            font-family: $font-secondary;
            margin: 0px 0px 25px;
            line-height: 32px;
        }
    }
    .text-red {
        color: red;
    }
    
    .action-buttons{
        background: $white;
        border-radius: 4px;
        border: 1px solid #D9DFE8;
        padding: 7px 14px 7px 12px;
        .p-button-label{
            color: $text-40;
            font-size: 13px;
            font-weight: 500;
        }
        .p-button-icon{
            color: $text-40;
            font-size: 12px;
        }
        &:hover{
            background: $blue-70;
            .p-button-icon, .p-button-label{
                color: $white;
            }
        }
        &.save-button{
            background: $blue-70;
            border: 1px solid $blue-70;
          
            .p-button-icon, .p-button-label{
                color: $white;
            }
            &:hover{
                background: $white;
                border: 1px solid #D9DFE8;
                .p-button-icon, .p-button-label{
                    color: $text-40;
                }
            }
        }
    }
    h1{
        margin: 0px !important;
        font-family: $font-secondary;
        font-size: 24px !important;
    }
    .edit-details{
        .card{
            label, .label{
                color: $text-40;
                font-size: 14px;
                font-weight: 500; 
            }
            .input-field{
                border-radius: 6px;
                border: 1px solid $blue-bg-40;
                background: $blue-bg-20;
                font-size: 18px;
                font-weight: 500;
                color: $text-40;
                font-family: $font-secondary;
                padding: 10px 20px;
            }
            .edit-file-upload{
                position: relative;
                .p-fileupload-choose{
                    width: 100%;
                    min-height: 100%;
                    background: none;
                    position: absolute;
                    border: none;
                    .p-button-label{
                        display: none;
                    }
                    .p-button-icon{
                        display: none;
                    }
                    &.p-fileupload-choose-selected{
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        .p-button-icon{
                            display: block;
                        }
                    }
                }
                .new-edit-image{
                    img{
                        width: 100%;
                        border-radius: 10px;
                    }
                }
            }
            .create-file-upload{
                border-radius: 4px;
                border: 1px dashed #C6C8D1;
                background: $bg-20;
                padding: 8px 7px;
                .p-fileupload-choose{
                    padding: 4px 12px;
                    background: $white;
                    border: 1px solid #EAECF0;
                    .p-button-label{
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                    }
                    svg{
                        color: $text-40;
                        width: 10px;
                        height: 10px;
                    }
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color:#9295A3;
                }
                &.add-more-files-upload{
                    border-radius: 0px;
                    border: none;
                    background: transparent;
                    padding: 0px;
                    .p-fileupload-choose{
                        padding: 8px 14px 8px 12px;
                        border-radius: 4px;
                        &:hover{
                            background: $text-40;
                            .p-button-label, svg{
                                color: $white;
                            }
                        }
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }
            .content-editor{
                .p-editor-container{
                    .p-editor-toolbar{
                        background: $blue-bg-20;
                    }
                    .p-editor-content{
                        .ql-editor{
                            background: $blue-bg-20;
                            &::-webkit-scrollbar {
                                width: 5px;
                                border-radius: 5px;
                            }
                            &::-webkit-scrollbar-track {
                                background: $blue-bg-40;
                                border-radius: 5px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: $gray-700;
                                padding: 2px;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
            .form-input{
                .p-dropdown{
                    background: $blue-bg-20;
                    border: 1px solid $blue-bg-40;
                    .p-dropdown-label{
                        font-size: 14px;
                        font-weight: 500;
                        color: $text-40;
                    }
                }
            }
            .status-box{
                background: $blue-bg-20;
                padding: 10px 14px;
                border-radius: 6px;
                border: 1px solid $blue-bg-40;
                .p-checkbox{
                    width: 18px;
                    height: 18px;
                    .p-checkbox-box{
                        width: 18px;
                        height: 18px;
                        background: #F3F3F3;
                        &:hover{
                            border-color: $text-40;
                        }
                        &.p-focus{
                            box-shadow: none;
                        }
                    }
                    &.p-checkbox-checked{
                        .p-checkbox-box{
                            background:$text-40;
                            border-color: $text-40;
                        }
                    }
                }
                label{
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-40;
                }
            }
            .p-multiselect{
                background: $blue-bg-20;
                border: 1px solid $blue-bg-40;
                .p-multiselect-label{
                    padding: 10px 14px;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-40;
                }
            }
        }
    }
    .preview-report{
        .report-img{
            img{
                width: 100%;
                height: 250px;
                object-fit: cover;
                border-radius: 8px;
            }
        }
        .relative{
            .overlay{
                position: absolute;
                width: 100%;
                height: 99%;
                text-align: center;
                background: rgba(0, 0, 0, 0.5);
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                h2{
                    color: $white;
                    font-size: 21px;
                    font-weight: 600;
                    margin: 0px;
                    width: 100%;
                    max-width: 150px;
                    line-height: 30px;
                    font-family: $font-base;
                }
            }
        }
        .attachments{
            .save-button{
                background: $blue-70;
                border-radius: 4px;
                border: none;
                padding: 8px 14px 8px 12px;
                min-width: 10rem;
                &.regulator{
                    background: $re-green-70;  
                }
                .p-button-label{
                    color: $white;
                    font-size: 13px;
                    font-weight: 500;
                }
                .p-button-icon{
                    color: $white;
                    font-size: 12px;
                }
                &:hover{
                    background: $text-40;
                }
            }
            .type-info{
                padding: 6px 16px;
                border-radius: 4px;
                border: 1px solid $blue-bg-40;
                background: $blue-bg-20;
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
        }
    }
}
.p-multiselect-panel{
    .p-inputtext{
        padding: 8px 14px;
    }
}