@import '../../../assets/scss/variables.scss';

// **** My Analytics Tab CSS **** //
.my-analytics-tab{
    height: calc(100% - 188px);
    min-height: calc(100vh - 188px);
    padding: 25px 70px;
    .heading-card{
        h1{
            font-size: 20px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            margin: 0px;
        }
        p{
            margin: 10px 0px 0px;
            color: $text-40;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .date-calendar{
        border: 1px solid #d1d5db;
        border-radius: 4px;
        .p-datepicker-trigger{
            background: $white;
            padding: 6px 6px 6px 10px;
            width: auto;
            color: $text-40;
            border: none;
            border-right: 0px;
            &:focus{
                box-shadow: none;
            }
        }
        .p-inputtext{
            padding:6px 10px 6px 6px;
            font-size: 13px;
            font-weight: 500;
            color: $text-40;
            border: 0px;
            width: auto;
            &:focus{
                box-shadow: none;
            }
        }
        &:hover{
            border-color: $text-20;
        }
    }
    .header-right{
        .date-calendar{
            width: 100%;
        }
        .multi-select-dropdown{
            width: 100%;
            max-width: 175px;
            min-width: 175px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            background: $white;
            .p-multiselect-label{
                padding: 8px 10px 8px 12px;
                color: $text-40;
                font-size: 13px;
                font-weight: 400;
            }
        }
        .export-button{
            background: $white;
            padding: 8px 14px 8px 12px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            .p-button-icon{
                color: $text-40;
                font-size: 12px;
            }
            &:hover{
                background: $text-40;
                .p-button-label{
                    color: $white;
                }
                .p-button-icon{
                    color: $white;
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .analytics-wrapper{
        margin-top: 1rem;
        .info-box{
            border-radius: 8px;
            border: 1px solid $home-box-border;
            background: $white;
            padding: 24px;
            color: $text-40;
            min-height: 162px;
            height: 100%;
            h4{
                margin: 0px;
                padding: 0px;
                color: $text-40;
                font-size: 14px;
                font-weight: 500;
            }
            p{
                margin: 10px 0px 0px;
                color: $text-30;
                font-size: 13px;
                font-weight: 400;
                min-height: 32px;
            }
            h2{
                font-size: 28px;
                font-weight: 600;
                color: $text-40;
                font-family: $font-secondary;
                margin:0px;
            }
            .inactive-link{
                pointer-events: none;
            }
            .active-link{
                pointer-events: all;
            }
            .info-span{
                color: $text-30;
                font-size: 13px;
                font-weight: 400;
                b{
                    color: $danger-20;
                    font-size: 14px;
                    font-weight: 500;
                    .pi{
                        font-size: 12px;
                    }
                }
            }
            .multi-select-dropdown{
                width: 100%;
                max-width: 175px;
                min-width: 175px;
                margin-right: 10px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                background: $bg-20;
                .p-multiselect-label{
                    padding: 7px 10px 7px 12px;
                    color: $text-40;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
            .export-button{
                padding: 8px;
                border-radius: 4px;
                border: 1px solid $bg-40;
                background: $bg-20;
                width: 32px;
                height: 32px;
                .p-button-icon{
                    color: $text-40;
                }
            }
            .header{
                flex-wrap: wrap;
                row-gap: 10px; 
                margin-bottom: 25px;
                span{
                    color: $text-30;
                    font-size: 13px;
                    font-weight: 400; 
                }
            }
            .horizontal-bar-chart{
                height: 75px;
            }
            .chart-info{
                padding: 0px 6px;
                min-height: 188px;
                ul{
                    margin: 0px 0px 19px;
                    padding: 0px;
                    li{
                        font-size: 12px;
                        font-weight: 500;
                        b{
                            font-weight: 600;
                        }
                        .indicator{
                            border-radius: 2px;
                            background: #B9BC19;
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            margin-right: 6px;
                            &.not-suspicious{
                                background: #FC702E;
                            }
                            &.not-offered{
                                background: #94C11F;
                            }
                        }
                    }
                }
            }
        }
    }
}
.p-multiselect-panel{
   max-width: 225px;
    .p-multiselect-header{
        padding: 0.5rem 0.5rem 0.5rem 1.25rem;
        .p-checkbox{
            width: 16px;
            height: 16px;
            .p-checkbox-box{
                width: 16px;
                height: 16px;
                border-radius: 2px;
                &.p-highlight{
                    background: $primary-60;
                    border-color: $primary-60;
                }
                &.p-focus{
                    box-shadow: none;
                }
            }
        }
    }
    .p-multiselect-items-wrapper{
        &::-webkit-scrollbar {
            width:6px;
        }
        &::-webkit-scrollbar-track {
            background: $bg-20;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $bg-40;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $text-20; 
        }
        .p-multiselect-items{
            .p-multiselect-item{
                span{
                    color: $text-40;
                    font-size: 13px;
                    font-weight: 400;
                }
                .p-checkbox{
                    width: 16px;
                    height: 16px;
                    .p-checkbox-box{
                        width: 16px;
                        height: 16px;
                        border-radius: 2px;
                        &.p-highlight{
                            background: $primary-60;
                            border-color: $primary-60;
                        }
                        &.p-focus{
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}
.single-select-dropdown{
    max-width: 175px;
    min-width: 175px;
    border-radius: 4px;
    border: 1px solid $bg-40;
    background: $bg-20;
    &.bg-white{
        background: $white;
    }
    .p-dropdown-label {
        padding: 7px 10px 7px 12px;
        font-family: $font-base;
        color: $text-40!important;
        font-size: 13px;
        font-weight: 400;
    }
}
.p-multiselect-filter, .p-dropdown-filter{
      padding: 0.5rem 0.75rem;;
}
.export-popup.p-menu-overlay{
    margin-top: 5px;
    padding: 0px;
    width: auto;
    .p-menu-list{
        .p-menuitem{
            .p-menuitem-content{
                border-radius: 4px;
                background: transparent;
            }
            .p-menuitem-link{
                border-radius: 4px;
                padding: 10px 14px;
                .p-menuitem-text{
                    font-size: 13px;
                    font-weight: 400;
                    color: $text-40;
                    line-height: 20px;
                    p{
                        margin: 0px;
                    }
                }
                .p-menuitem-icon{
                    color: $text-30;
                    font-weight: 500;
                    font-size: 12px;
                }
                &:hover{
                    background: $bg-20;
                }
            }
        }
    }
}
.export-dialog{
    width: 100%;
    max-width: 365px;
    .p-dialog-header{
        display: none;
    }
    .p-dialog-content{
        border-radius: 6px 6px 0px 0px;
        border-bottom: 1px solid #EAECF0;
        padding: 1.2rem 1.5rem;
        .export-header-section{
            h2{
                font-size: 20px;
                font-weight: 500;
                color: $text-40;
                font-family: $font-secondary;
                margin: 0px;
            }
            p{
                padding-top: 20px;
                margin: 0px;
                font-size: 15px;
                font-weight: 500;
                color: $text-40;
                margin-bottom: 16px;
            }
        }
       
    }
    .export-body-section{
        display: grid;
        margin: 0px;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);
        .export-btns{
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $bg-20;
            span {
                font-family: $font-base;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                color: $text-40;
            }
            &:hover{
                border: 1px solid $bg-40; 
            }
            &.export-highlight{
                background: $blue-70;
                span {
                    color: $white;
                }
            }
        }
    }
    .p-dialog-footer{
        padding: 1rem 20px;
        .logout-btns{
            padding: 8px 14px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            background: $white;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            &.click-btn{
                background: $primary-60;
                border: 1px solid $primary-60;
                &.regulator{
                    background: $re-green-70;
                    border: 1px solid $re-green-70;
                }
                .p-button-label{
                    color: $white;
                }
                &:hover{
                    background: $gray-900;
                    border: 1px solid $gray-900;
                }
                .p-button-icon{
                    color: $white;
                    font-size: 12px;
                }
            }
            &:hover{
                border: 1px solid $text-40;
                background: $text-60;
                .p-button-label{
                    color: $white;
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
}