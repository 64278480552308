@import '../../assets/scss/variables';

.notfound-section {
    .custom-height {
        height: 100%;
        padding: 3% 0px 5%;

        h1 {
            margin: 0px 0px 45px;
        }

        .notfound-img {
            img {
                width: 100%;
                max-width: 500px;
            }
        }

        .backtohome-btn {
            background: $primary-70;
            border: none;
            padding: 0.75rem 1.25rem;
            font-size: 0.9rem;
            border-radius: 6px;
            color: $white;

            &:hover {
                background: $primary-60;
            }

            .p-button-label {
                font-weight: 500;
            }

            .pi {
                top: 2px;
                position: relative;
                right: 1px;
            }
        }
    }
}

// Responsive CSS

@media (max-width:768px) {
    .notfound-section {
        .custom-height {
            padding: 30px 0px 50px;
        }
    }
}