@import '../../../assets/scss/variables.scss';

// **** Home Tab CSS **** //
.home-tab{
    height: calc(100% - 300px);
    min-height: calc(100vh - 300px);
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    padding: 35px 70px;
    height: calc(100% - 275px);
    min-height: calc(100vh - 275px);
    .logo-img{
        img{
            width: 100%;
            max-width: 375px;
        }
    }
    p{
        font-size: 16px;
        font-weight: 500;
        color: $text-40;
        line-height: 21px;
    }
    .info-box{
        border-radius: 8px;
        border: 1px solid $home-box-border;
        background: $white;
        padding: 21.5px 0px 24.5px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $text-40;
        &.primary-bg{
            background: $primary-60;
            border: 1px solid #AB3800;
            color: $white;
            p{
                color: $white;
            }
            &:hover{
                background: $text-40;
                border: 1px solid $text-30;
            }
        }
        h2{
            font-size: 24px;
            font-weight: 700;
            margin: 0px; 
            line-height: 36px;
            font-family: $font-secondary;
            .pi{
                font-weight: 600;
            }
        }
        p{
            margin: 5px 0px 0px;
            font-size: 15px;
            font-weight: 500;
        }
    }
    .latest-news-section{
        padding: 16px;
        border-radius: 8px;
        border: 1px solid $home-box-border;
        background: $white;
        .newsImg-outer {
            .news-img{
                width: 100%;
                display: block;
                height: 100%;
                line-height: 0px;
                img{
                    width: 100%;
                    border-radius: 8px;
                    height: auto;
                    max-height: 145px;
                    object-fit: cover;
                }
            }
        }
        .news-skeleton{
            max-height: 150px;
        }
        h3{
            font-size: 20px;
            font-weight: 500;
            margin: 0px; 
            line-height: 26px;
            font-family: $font-secondary;
            color: $text-40;
        }
        p{
            font-size: 14px;
            font-weight: 500;
            color: $text-30;
            line-height: 20px;
            margin-top: 4px;
            margin-bottom: 0px;
        }
        .desc-content {
            word-break: break-word;
            white-space: normal;
            cursor: pointer;
            min-height: 80px;
            *{
            font-size: 13px !important;
            font-weight: 500 !important;
            color: $text-30 !important;
            line-height: 22px !important;
            margin:  6px 0px 0px !important;
            }
            p{
                font-size: 13px;
                font-weight: 500;
                color: $text-30;
                line-height: 22px;
                margin: 6px 0px 0px;
                &:first-letter {
                    text-transform: uppercase;
                  }
            }
        }
        .desc-content > *:last-child::after {
            content: '...see more';
            color: $text-40;
        }
        ul{
            margin: 15px 0px 0px;
            padding: 0px;
            li{
                font-size: 13px;
                font-weight: 400;
                color: $text-40;
                .pi{
                    font-size: 13px;
                    color: $text-30;
                    margin-right: 5px;
                }
                span{
                    &.author{
                        font-weight: 500;
                    }
                }
            }
        }
    }
}