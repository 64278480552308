.main-dialog{
    height: 210px;
    width: 380px;
}
.custom-cancel-button {
    background-color: white !important;
    color: #333 !important;
    border: 1px solid #ddd !important;
    font-size: 14px;
  
  }
  
  .custom-delete-button {
    background-color: #0056b3 !important;
    color: white !important;
    border: none !important;
    font-size: 14px;
  }
 
  .custom-delete-button:hover {
    background-color: #004494 !important;
  }
  
  .custom-cancel-button:hover {
    background-color: #f8f9fa !important;
    color: #333 !important;
  }
  
 