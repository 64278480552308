@import '../../../assets/scss/variables.scss';

.filter-section{
    position: absolute;
    right: 0px;
    top: -58px;
    .create-button{
        background: $blue-70;
        padding: 8px 12px 8px 14px;
        border-radius: 4px;
        border: none;
        .p-button-label{
            font-size: 13px;
            font-weight: 500;
            color: $white;
        }
        .p-button-icon{
            color: $white;
            font-size: 12px;
        }
        &:hover{
            background: $text-40;
        }
        &:focus{
            box-shadow: none;
        }
    }
    .search-box{
        .search-field{
            border-radius: 4px;
            border: 1px solid $bg-40;
            background: $white;
            padding: 8px 14px 8px 40px;
            font-size: 13px;
            font-weight: 400;
            color: $text-30;
            height: 36px;
        }
    }
}

.all-alerts-section{
    &.all-tags-section{
        .alerts-tab{
            .data-table{
                .p-datatable-wrapper{
                    .p-datatable-table{
                        .p-datatable-thead {
                            tr {
                                th {
                                    padding: 1rem 1rem !important;
                                }
                            }
                        }
                        .p-datatable-tbody{
                            tr{
                                td{
                                    .view-details-button{
                                        &.only-icon{
                                            width: 28px;
                                            height: 28px;
                                            border-radius: 4px;
                                            .p-button-icon{
                                                color: $text-30;
                                                font-size: 13px;
                                            }
                                            &:hover{
                                                .p-button-icon{
                                                    color: $white;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.tags-dialog{
    .dialog-header{
        p{
            font-size: 14px;
            color: $text-30;
            font-weight: 400;
            margin: 15px 0px 0px;
        }
    }
    .dialog-body-section{
        label{
            font-size: 13px;
            color: $text-40;
            font-weight: 400;
            margin-bottom: 2px;
        }
        .p-inputtext{
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $bg-20;
            padding: 10px 14px;
            height: 32px;
        }
        .description-textarea{
            width: 100%;
            resize: none;
            padding: 10px 14px;
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $bg-20;
            min-height: 145px;
            font-size: 13px;
            color: $text-30;
            font-weight: 400;
        }
    }
    .p-dialog-footer{
        .logout-btns{
            &.click-btn{
                background: $blue-70;
                border: 1px solid $blue-70;
            }
        }
    }
}
.error{
    color :red;
}