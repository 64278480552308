@import '../../assets/scss/variables';

// Login Screen CSS
.login-section{
    background: url('../../assets/images/login-bg.png') no-repeat center, $primary-70;
    width: 100%;
    height: 100%;
    .login-wrapper{
        width: 98%;
        text-align: center;
        margin: 0 auto;
        color: $white;
        height: calc(100vh - 106px);
        min-height: 550px;
        .login-info{
            background: $white;
            padding: 30px 28px;
            width: 100%;
            max-width: 375px;
            border-radius: 8px;
            .logo-img{
                img{
                    width: 100%;
                    max-width: 128px;
                }
            }
            h1{
                font-size: 28px;
                font-weight: 500;
                font-family: $font-secondary;
                color: $gray-900;
                margin: 30px 0px 8px;
            }
            p{
                font-size: 13px;
                font-weight: 500;
                color: $gray-600;
                line-height: 21px;
                margin: 0rem;
            }

            // Login Form CSS
            form{
                margin-top: 1.5rem;
                text-align: left;
                label{
                    color: $text-40;
                    font-size: 13px;
                }
                .p-inputtext{
                    height: 32px;
                    border-radius: 6px;
                    border: 1px solid $bg-40;
                    background: $bg-20;
                    font-size: 13px;
                    color: $text-40;
                    width: 100%;
                }
                .p-password{
                    .p-icon-field{
                        width: 100%;
                    }
                    svg{
                        &:focus{
                            outline: 0px;
                        }
                    }
                }
                .p-checkbox{
                    width: 16px;
                    height: 16px;
                    .p-checkbox-box{
                        width: 16px;
                        height: 16px;
                        border-radius: 4px;
                        &.p-highlight{
                            background: $text-40;
                            border-color: $text-40;
                        }
                        &.p-focus{
                            box-shadow: none;
                        }
                    }
                }
                .link-p{
                    font-size: 13px;
                    font-weight: 500;
                    margin: 0px;
                    a{
                        color: $text-30;
                        &:hover{
                            color: $primary-50;
                        }
                    }
                }
                .link-success{
                    color: $success-30;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    margin: 0px;
                    .pi-check-circle{
                        font-size: 12px;
                        position: relative;
                        top: 4px;
                        margin-right: 6px;
                        font-weight: 600;
                    }
                }
                .signin-button{
                    height: 32px;
                    padding: 8px 14px 9px;
                    border-radius: 4px;
                    border: none;
                    background: $text-40;
                    color: $white;
                    font-size: 13px;
                    font-weight: 500;
                    &:hover{
                        background: $primary-70;
                        color: $white;
                    }
                }
                .error{
                    color: $primary-70;
                    font-size: 13px;
                    &:first-letter{
                        text-transform: uppercase;
                    }
                }
                .otp-input-block{
                    .p-inputtext{
                        width: 37px;
                    }
                    .resend-label{
                        color: $success-30;
                        cursor: pointer;
                        &:hover{
                            color: $success-30;
                        }
                    }
                }
            }
        }
    }

    // Footer Links CSS
    ul{
        padding: 1.8rem 0px;
        color: $white;
        li{
            font-size: 13px;
            font-weight: 400;
            margin: 0rem 0.4rem;
            &.line{
                opacity: 0.3;
                font-size: 11px;
            }
            a{
                color: $white;
                &:hover{
                    opacity: 0.5;
                } 
            }
        }
    }
}

// Responsive CSS

@media (max-width:520px){
    .login-section{
        .login-wrapper{
            .login-info{
                padding: 25px;
                max-width: 320px;
            }
        }
    }
}