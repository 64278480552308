// Font Styles
$font-base: "Inter", sans-serif;
$font-secondary: "Roboto Slab", serif;

// Colors
$primary-70: #D04A09;
$primary-60: #E9540D;
$primary-50: #FC702E;
$primary-40: #FF8F5A;
$primary-30: #FFB18C;
$primary-20: #FFD7C4;
$primary-10: #FFF7F3;

$secondary-50: #FFF7F3;
$secondary-40: #EEF0EF;
$secondary-30: #F5F7F6;

$bg-20:#F5F5F7;
$bg-40:#DEDEDE;
$bg-30:#F5F5F7;

$home-bg:#F4F8F5;

$text-60: #06070A;
$text-40: #151518;
$text-30: #6E6E77;
$text-20: #ADADB7;

$gray-600: #475467;
$gray-700: #344054;
$gray-900: #101828;

$black: #161b28;
$white: #ffffff;

$danger-30: #A1150C;
$danger-20: #D31A0E;
$danger-10: #FFF4F3;
$warning-20: #BA9D00;
$success-30: #228002;
$success-20: #2FB500;

$border-gray: #E9E6E6;
$home-box-border:#DCE2DD;
$danger-border: #D31A0E;

$blue-70:#0A45A9;
$blue-bg-20:#F1F3F6;
$blue-bg-30:#ECEEF3;
$blue-bg-40:#CFD8E3;

// Regulator dashboard colors
$re-primary-60:#7DA70F;
$re-text-20:#B0B7AD;
$re-bg-40:#D7DEC5;
$re-green-70: #6A9106;
