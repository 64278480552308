@import '../../assets/scss/variables.scss';

.create-alert-section{
    padding: 50px 70px 20px;
    height: calc(100% - 204px);
    min-height: calc(100vh - 204px);
    h1{
        font-size: 24px;
        font-weight: 500;
        margin: 0rem 0rem 1.6rem;
        font-family: $font-secondary;
    }
    .create-alert-wrapper{
        width: 95%;
        max-width: 800px;
        margin: 0 auto;
        .form-section{
            background: $white;
            border-radius: 12px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
            border: 1px solid #EAECF0;
            .error{
                color: $primary-70;
                font-size: 13px;
            }
            .form-body{
                padding: 25px;
                label, .label{
                    font-size: 13px;
                    font-weight: 400;
                    color: $text-40;
                    line-height: 20px;
                    .important{
                        color: $danger-20;
                    }
                }
                .form-input{
                    .p-inputtext{
                        padding: 8px 14px;
                        border-radius: 6px;
                        border: 1px solid $bg-40;
                        background: $bg-20;
                        font-size: 13px;
                        color: $text-40;
                        width: 100%;
                    }
                }
            }
            .form-input{
                .p-inputtext{
                    padding: 8px 14px;
                    border-radius: 6px;
                    border: 1px solid $bg-40;
                    background: $bg-20;
                    font-size: 13px;
                    color: $text-40;
                    width: 100%;
                }
                .p-inputtext.p-autocomplete-input{
                    background: $white;
                    border: 1px solid #d1d5db;
                    border-radius: 6px;
                }
            }
            .alert-drop-down{
                padding: 0px;
                border-radius: 6px;
                border: 1px solid $bg-40;
                background: $bg-20;
                font-size: 13px;
                color: $text-40;
                width: 100%;
                .p-dropdown-label{
                    text-align: left;
                    padding: 8px 14px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                }
                .p-dropdown-trigger{
                    svg{
                        width: 14px;
                        height: 14px;
                    }
                }
            }
            .events-section{
                border-radius: 6px;
                background: $bg-20;
                padding: 25px;
                margin-top: 15px;
                .custom-col{
                    width: 25px;
                }
                .number{
                    font-size: 13px;
                    font-weight: 600;
                    color: $text-40;
                }
                .date-calendar{
                    border: 1px solid #d1d5db;
                    border-radius: 6px;
                    .p-datepicker-trigger{
                        background: $white;
                        padding: 0px 14px;
                        width: auto;
                        color: $black;
                        border: none;
                        border-right: 0px;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                    .p-inputtext{
                        padding: 8px 14px;
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                        border: 0px;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                    &:hover{
                        border-color: $bg-40;
                    }
                }
                .p-inputtext, .alert-drop-down{
                    background: $white !important;
                }
                hr{
                    border-color: $border-gray;
                    border-top: 0px;
                }
            }
            .p-checkbox{
                width: 16px;
                height: 16px;
                .p-checkbox-box{
                    width: 16px;
                    height: 16px;
                    border-radius: 4px;
                    &.p-highlight{
                        background: $text-40;
                        border-color: $text-40;
                    }
                    &.p-focus{
                        box-shadow: none;
                    }
                }
            }
            .capitalize-first::first-letter{
                text-transform: capitalize;
            }
            .p-radiobutton{
                width: 16px;
                height: 16px;
                .p-radiobutton-box{
                    border: 1px solid $bg-40;
                    width: 16px;
                    height: 16px;
                    .pi.pi-trash {
                        margin-left: 6px;
                        color: #9295A3;
                        font-size: 12px;
                    }
                }
                &.p-radiobutton-checked{
                    .p-radiobutton-box{
                        background: $bg-20;
                    }
                    .p-radiobutton-icon{
                        width: 8px;
                        height: 8px;
                        background: $gray-700;
                    }
                }
            }
            .customer_info{
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 0.44px;
                color: $text-30;
                position: relative;
                margin: 20px 0px 15px;
                &::after{
                    content: '';
                    width: 82%;
                    height: 1px;
                    background: $border-gray;
                    position: absolute;
                    right: 0px;
                    top: 7px;
                }
            }
            .description-textarea{
                border-radius: 6px;
                border: 1px solid $bg-40;
                background: $bg-20;
                width: 100%;
                resize: none;
                font-size: 13px;
                font-weight: 400;
                color: $text-30;
            }
            .file-upload{
                border-radius: 4px;
                border: 1px dashed #C6C8D1;
                background: $bg-20;
                padding: 6px 7px;
                .p-fileupload-buttonbar{
                    padding: 0rem;
                    border: none;
                }
                .p-fileupload-content{
                    display: none;
                }
                .p-fileupload .p-fileupload-buttonbar .p-button{
                    margin: 0px;
                }
                .p-fileupload-choose{
                    padding: 4px 12px;
                    background: $white;
                    border: 1px solid #EAECF0;
                    white-space: nowrap;
                    .p-button-label{
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-40;
                    }
                    svg{
                        color: $text-40;
                        width: 10px;
                        height: 10px;
                    }
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color:#9295A3;
                }
            }
            .uploaded-file-container{
                padding: 4px;
                .uploaded-files{
                    padding: 4px 10px 4px 8px;
                    border-radius: 4px;
                    border: 1px solid #EAECF0;
                    color: #151518;
                    font-size: 13px;
                    font-weight: 500;
                    width: fit-content;
                    .pi.pi-trash {
                        margin-left: 6px;
                        color: #9295A3;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
            }
            .addmore-button{
                background: $white;
                border-radius: 4px;
                border: 1px solid #D9DFE8;
                padding: 8px 14px 8px 12px;
                width: 100%;
                max-width: 110px;
                .p-button-label{
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                }
                .p-button-icon{
                    color: $text-40;
                    font-size: 13px;
                }
                &:hover{
                    border: 1px solid $text-20;
                }
                &:focus{
                    box-shadow: none;
                }
            }
        }
        .form-footer{
            padding: 15px 0px 0px;
            .create-alert-button{
                border: none;
                border-radius: 4px;
                background: $primary-60;
                padding: 10px 18px;
                .p-button-label{
                    font-size: 14px;
                    font-weight: 500;
                }
                &:hover{
                    background: $black;
                }
            }
            .cancel-button{
                border-radius: 4px;
                background: $white;
                padding: 9px 18px;
                border: 1px solid $bg-40;
                .p-button-label{
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-40;
                }
                &:hover{
                    background: $text-40;
                    .p-button-label{
                        color: $white;
                    }
                }
            }
        }
        
    }
}
.p-dropdown-panel{
    .p-dropdown-items-wrapper{
        .p-dropdown-items{
            padding: 10px;
            .p-dropdown-item{
                font-size: 13px;
                font-weight: 400;
                color: $text-40;
                padding: 10px;
                border-radius: 6px;
                &:hover{
                    background: $secondary-30;
                }
            }
        }
    }
}
.editAlert{
    .p-autocomplete-item{
        font-size: 13px;
    }
}