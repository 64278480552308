@import '../../../../assets/scss/variables.scss';

.create-news-dialog {
  max-width: 400px;
  width: 100%;
  height: 100%;
  max-height: 355px;
  .p-dialog-header {
    display: none;
  }
  .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 1rem 1.5rem 0rem 1.5rem;
  }
  .dialog-header {
    margin-bottom: 1rem;
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0px;
      padding: 0px;
      color: $text-40;
      font-family: $font-secondary;
      margin-bottom: 0.5rem;
    }
    p {
      color: #6c757d;
      margin: 0;
    }
  }
  .dialog-body-section {
    .p-field-radiobutton {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      background-color: #F2F5FB;
      padding: 0.5rem;
      border-radius: 0.25rem;
      .p-radiobutton {
        margin-right: 1rem;
        .p-radiobutton-box {
          border-color: rgba(52, 111, 175, 0.726);
          &.p-highlight {
            border-color: rgba(52, 111, 175, 0.726);
          }
        }
      }
      label {
        font-size: 15px;
        font-weight: 600;
        color: #343a40;
      }
    }
  }
  .p-dialog-footer {
    padding: 1rem 1.5rem;
    display: flex;
   
    justify-content: flex-end;
    .news-btns {
      margin-left: 1rem;
      font-size: 13px;
      font-weight: 500;
      &:first-child {
        background-color: #ffffff;
        color: #6c757d;
        border: 1px solid  #343a40;
        &:hover {
          background-color: black;
          color: white;
        }
      }
      &:last-child {
        background-color:  $blue-70;
        color: #ffffff;
        border: 1px solid  $blue-70;
        
        &:hover {
          background-color: black;
          color: white;
        }
      }
    }
  }
}
