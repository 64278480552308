@import '../../../assets/scss/variables.scss';

.filter-section {
    position: absolute;
    right: 0px;
    top: -58px;
    &.user-management-filter{
        top: -68px;
    }

    .create-button {
        background: $blue-70;
        padding: 8px 12px 8px 14px;
        border-radius: 4px;
        border: none;

        .p-button-label {
            font-size: 13px;
            font-weight: 500;
            color: $white;
        }

        .p-button-icon {
            color: $white;
            font-size: 12px;
        }

        &:hover {
            background: $text-40;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .search-box {
        .search-field {
            border-radius: 4px;
            border: 1px solid $bg-40;
            background: $white;
            padding: 8px 14px 8px 40px;
            font-size: 13px;
            font-weight: 400;
            color: $text-30;
            height: 36px;
            width: 18rem;
        }
    }
}
.spine-loader-bk{
    backdrop-filter: blur(1px);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        font-size: 50px;
        color: black;
    }
}
.all-alerts-section {
    &.all-tags-section {
        h1 {
            font-size: 24px;
        }

        .alerts-tab {
            .data-table {
                .p-datatable-thead > tr > th {
                    padding: 1.5rem 1.5rem;
                }
                .p-datatable-footer{
                    padding: 0;
                    border: 0;
                }
            
                .p-datatable-wrapper {
                    .p-datatable-table {
                        .p-datatable-tbody {
                            tr {
                                td {
                                    .view-details-button {
                                        &.only-icon {
                                            width: 28px;
                                            height: 28px;
                                            border-radius: 4px;

                                            .p-button-icon {
                                                color: $text-30;
                                                font-size: 13px;
                                            }

                                            &:hover {
                                                .p-button-icon {
                                                    color: $white;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.important {
    color: red;
}

.tags-dialog {
    .dialog-header {
        p {
            font-size: 14px;
            color: $text-30;
            font-weight: 400;
            margin: 15px 0px 0px;
        }
    }

    .dialog-body-section {
        label, .label {
            font-size: 13px;
            color: $text-40;
            font-weight: 400;
            margin-bottom: 2px;
        }

        .p-inputtext {
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $bg-20;
            padding: 10px 14px;
            height: 32px;
            font-size: 13px;
            color: $text-40;
            font-weight: 400;
            width: 100%;
        }

        .multi-select-dropdown {
            width: 100%;
            border-radius: 4px;
            border: 1px solid $bg-40;
            background: $bg-20;

            .p-multiselect-label {
                padding: 8px 10px 8px 12px;
                color: $text-40;
                font-size: 13px;
                font-weight: 400;
            }

            .p-inputtext {
                border: none;
                background: none;
                padding: 7px 12px;
                color: $text-40;
            }
        }

        .description-textarea {
            width: 100%;
            resize: none;
            padding: 10px 14px;
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $bg-20;
            min-height: 145px;
            font-size: 13px;
            color: $text-40;
            font-weight: 400;
        }

        .box {
            background: $bg-20;
            padding: 8px 14px;
            border-radius: 6px;
            width: 100%;

            .p-radiobutton {
                width: 18px;
                height: 18px;

                .p-radiobutton-box {
                    border: 1px solid $bg-40;
                    width: 18px;
                    height: 18px;
                }

                &.p-radiobutton-checked {
                    .p-radiobutton-box {
                        background: $bg-20;
                        border: 1px solid $blue-70;
                    }

                    .p-radiobutton-icon {
                        width: 8px;
                        height: 8px;
                        background: $blue-70;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }

        .profile-photo-upload {
            border-radius: 4px;
            border: 1px dashed #c6c8d1;
            background: $bg-20;
            padding: 3px 6px;

            .p-fileupload-buttonbar {
                padding: 0rem;
                border: none;
            }

            .p-fileupload-content {
                display: none;
            }

            .p-fileupload .p-fileupload-buttonbar .p-button {
                margin: 0px;
            }

            .p-fileupload-choose {
                padding: 3px 10px;
                background: $white;
                border: 1px solid #eaecf0;
                white-space: nowrap;

                .p-button-label {
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                }

                svg {
                    color: $text-40;
                    width: 10px;
                    height: 10px;
                }

                &:hover {
                    background: $blue-70;

                    .p-button-label {
                        color: $white;
                    }

                    svg {
                        color: $white;
                    }
                }
            }

            span {
                font-size: 12px;
                font-weight: 400;
                color: #9295a3;
            }
        }
    }

    .dialog-footer {
        text-align: right;

        .logout-btns {
            background: $white;
            border: 1px solid $bg-40;
            border-radius: 4px;
            padding: 8px 14px;
            color: $black;
            font-size: 13px;
            margin: 10px 0px 0px 10px;

            &.click-btn {
                color: #fff;
                background: $blue-70;
                border: 1px solid $blue-70;
                border-radius: 4px;
                padding: 8px 14px;
                font-size: 13px;
            }

            &:hover {
                background: $black;
                color: $white;
            }
        }
    }
}
.usermanagement-section .multi-select-dropdown .p-multiselect-label {
    padding: 9px 10px 8px 12px;
    font-size: 13px;
    font-weight: 400;
    width: 10rem;
}
.role-section{
    width: 100%;
    margin: 5px 14px;
}