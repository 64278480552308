@import '../../../../assets/scss/variables.scss';

.all-users-section{
    margin-top: 1rem;
    h1{
        font-size: 24px;
        span{
            color: $text-30;
        }
    }
    .small-text-input {
        font-size: 13px;
        width: 200px;
        height: 35px;
        padding: 4px 8px;
     
    }
    .create-button{
        background: $blue-70;
        padding: 8px 12px 8px 14px;
        border-radius: 4px;
        border: none;
        .p-button-label{
            font-size: 13px;
            font-weight: 500;
            color: $white;
        }
        .p-button-icon{
            color: $white;
            font-size: 12px;
        }
        &:hover{
            background: $text-40;
        }
        &:focus{
            box-shadow: none;
        }
    }
    .info-button{
        padding: 8px;
        border-radius: 4px;
        border: 1px solid $bg-40;
        background: $white;
        width: 32px;
        height: 32px;
        margin-left: 10px;
        .p-button-icon{
            color: $text-40;
        }
        &:hover{
            background: $blue-70;
            .p-button-icon{
                color: $white;
            }
        }
    }
    .status{
        font-size: 12px;
        font-weight: 500;
        padding: 3px 8px;
        align-items: center;
        border-radius: 16px;
        display: inline-flex;
        border: 1.5px solid #E2E6ED;
        &.success{
            border: 1.5px solid $success-20;
            color: $success-20;
        }
        &.managed{
            background: #E2E6ED;
        }
    }
    .alert-drop-down{
        padding: 0px;
        border-radius: 6px;
        border: 1px solid $bg-40;
        background: $white;
        font-size: 13px;
        color: $text-40;
        width: 160px;
        max-width: 160px;
        min-width: 160px;
        .p-dropdown-label{
            text-align: left;
            padding: 8px 14px;
            font-size: 13px;
            font-weight: 500;
            color: $text-40;
        }
        .p-dropdown-trigger{
            svg{
                width: 14px;
                height: 14px;
            }
        }
    }
    .view-details-button{
        &.only-icon{
            width: 28px;
            height: 28px;
            border-radius: 4px;
            .p-button-icon{
                color: $text-30;
                font-size: 13px;
            }
            &:hover{
                .p-button-icon{
                    color: $white;
                }
            }
        }
    }
    .p-checkbox{
        width: 16px;
        height: 16px;
        .p-checkbox-box{
            width: 16px;
            height: 16px;
            border-radius: 4px;
            &.p-highlight{
                background: $blue-70;
                border-color: $blue-70;
            }
            &.p-focus{
                box-shadow: none;
            }
        }
    }
    .permissions-section{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        padding: 30px;
        margin-top: 1.3rem;
        span{
            font-size: 13px;
            font-weight: 400;
            color: $text-60;
        }
        .save-button{
            padding: 6px 14px;
            border-radius: 6px;
            border: 1px solid $border-gray;
            background:$white;
            .p-button-label{
                font-size: 12px;
                font-weight: 500;
                color: $text-40;
            }
            &:hover{
                background: $text-60;
                border: 1px solid $text-60;
                .p-button-label{
                    color: $white;
                }
            }
        }
        &.sports-section{
            h3{
                margin: 0px;
            }
            .sports-block{
                display: flex;
                flex-wrap: wrap;
            }
            .sports-list{
                flex: 0 250px;
                // display: inline-block;
                background: $bg-20;
                padding: 7px 12px 7px 8px;
                border-radius: 6px;
                border: 1px solid $bg-40;
                margin: 0px 10px 10px 0px;
                .p-image{
                    line-height: 0px;
                    display: block;
                }
                .sports-icon img {
                    width: 20px;
                }
            }
        }
    }
    .switch-button{
        margin: 20px 0px;
        .p-inputswitch {
            width: 30px;
            height: 17px;
            .p-inputswitch-slider{
                border-radius: 9.6px;
                border: 0.8px solid $bg-40;
                background: $bg-20;
                &::before{
                    width: 13px;
                    height: 13px;
                    background: $bg-40;
                    margin-top: -7px;
                    left: 2px;
                }
            }
            &.p-disabled {
                &+span{
                    opacity: .5;
                }
                .p-inputswitch-slider{
                    border-color: $bg-40;
                    &:before{
                        background-color: $bg-40;
                    }
                }
            }
            &.p-inputswitch-checked{
                .p-inputswitch-slider{
                    background: $blue-70;
                    &::before{
                        transform: translateX(13px);
                        background: $white;
                        left: 1px;
                    }
                }
            }
            &.p-focus{
                .p-inputswitch-slider{
                    box-shadow: none;
                }
            }
        }
    }
}
.tags-dialog{
    .file-upload{
        border-radius: 4px;
        border: 1px dashed #C6C8D1;
        background: $bg-20;
        padding: 6px 7px;
        .p-fileupload-choose{
            padding: 4px 12px;
            background: $white;
            border: 1px solid #EAECF0;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            svg{
                color: $text-40;
                width: 10px;
                height: 10px;
            }
        }
        span{
            font-size: 12px;
            font-weight: 400;
            color:#9295A3;
        }
        &:hover{
            .p-fileupload-choose{
                background: $blue-70;
                .p-button-label{
                    color: $white;
                }
                svg{
                    color: $white;
                }
            }
        }
    }
}

.user-management-save-button{
    padding: 8px 14px;
    border-radius: 4px;
    border: 1px solid $blue-70;
    background: $blue-70;
    top: 6px;
    position: absolute;
    right: 0px;
    z-index: 2;
    .p-button-label{
        font-size: 13px;
        font-weight: 500;
        color: $white;
    }
    &:hover{
        background: $text-60 !important;
        border: 1px solid $text-60 !important;
        .p-button-label{
            color: $white !important;
        }
    }
    &:disabled {
        background: $white;
        border: 1px solid $border-gray;
        .p-button-label{
            color: $text-40;
        }
    }
}