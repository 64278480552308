@import '../../assets/scss/variables.scss';

// **** News Tab CSS **** //
.news-tab{
    font-family: $font-base;
    height: calc(100% - 185px);
    min-height: calc(100vh - 185px);
    padding: 25px 70px;
    .slider-wrapper{
        position: relative;
        .type{
            border-radius: 16px;
            border: 1px solid #A9A9A9;
            background: rgba(30, 30, 30, 0.30);
            padding: 2px 10px;
            font-size: 12px;
            font-weight: 500;
            color: #FFF7F3;
            right: 25px;
            position: absolute;
            top: 25px;
            z-index: 1;
        }
        .news-slider{
            position: relative;
            margin-bottom: 1rem;
            .p-galleria-item {
                overflow: hidden;
                border-radius: 8px;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(6, 7, 10, 0.55);
                    z-index: 1;
                    border-radius: 8px;
                }
               img{
                max-height: 230px;
                // object-fit: cover;
                opacity: 0.9;
                &.containImg{
                    object-fit: contain;
                }
                &.coverImage{
                    object-fit: cover;
                }
               }
            }  
            .p-galleria-caption{
                background: none;
                padding: 20px 25px;
                z-index: 9;
                .description{
                    width: 89%;
                }
                .category{
                    border-radius: 16px;
                    background: rgba(255, 255, 255, 0.20);
                    padding: 5px 10px;
                    color: #FFF7F3;
                    font-size: 12px;
                    font-weight: 500;
                }
                .title{
                    font-size: 18px;
                    font-weight: 500;
                    color: $white;
                    font-family: $font-secondary;
                    margin-top: 20px;
                }
                .description{
                    margin: 0px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $white;
                    margin-top: 8px;
                    line-height: 21px;
                }
            }
            .p-galleria-thumbnail-wrapper{
                .p-galleria-thumbnail-container{
                    background: none;
                    position: absolute;
                    right: 25px;
                    bottom: 6px;
                    .p-link{
                        border: 1px solid rgba(208, 208, 211, 0.20);
                        background: rgba(255, 255, 255, 0.10);
                        margin: 5px;
                        &.p-disabled{
                            svg{
                                color: $primary-50;
                            }
                        }
                    }
                }
            }
        }
    }
    .updates-section{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: #E2EBE4;
        padding: 18px;
        .icon-wrapper{
            border-radius: 8px;
            background: $white;
            padding: 16px;
            width: 70px;
            height: 70px;
        }
        h2{
            font-size: 15px;
            font-weight: 600;
            color: $text-40;
            margin: 0px 0px 8px;
        }
        p{
            font-size: 13px;
            font-weight: 500;
            color: $text-40;
            margin: 0px;
            line-height: 20px;
        }
        .see-all-button{
            background: $white;
            padding: 8px 12px 8px 14px;
            border-radius: 4px;
            border: 1px solid $bg-40;
            .p-button-label{
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
            }
            .p-button-icon{
                color: $text-40;
                font-size: 12px;
            }
            &:hover{
                background: $text-40;
                .p-button-label{
                    color: $white;
                }
                .p-button-icon{
                    color: $white;
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .new-list-section{
        h1{
            font-size: 20px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            margin: 0px;
        }
        .date-calendar{
            border: 1px solid #d1d5db;
            border-radius: 6px;
            .p-datepicker-trigger{
                background: $white;
                padding: 7px 6px 7px 12px;
                width: auto;
                color: $text-40;
                border: none;
                border-right: 0px;
                &:focus{
                    box-shadow: none;
                }
            }
            .p-inputtext{
                padding: 7px 14px 7px 6px;
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
                border: 0px;
                &:focus{
                    box-shadow: none;
                }
            }
            &:hover{
                border-color: $text-20;
            }
        }
        .search-bar{
            .search-box{
                border-radius: 8px;
                border: 1px solid $bg-40;
                background: $white;
                padding: 8px 14px 8px 40px;
                font-size: 13px;
                font-weight: 400;
                color: $text-30;
                height: 36px;
            }
        }
        .list-wrapper{
            padding: 25px;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid $bg-40;
            background: $white;
            margin-top: 1rem;
            .news-list{
                .news-cover{
                    .news-img{
                        width: 230px;
                        img{
                            width: 100%;
                            border-radius: 8px;
                            height: auto;
                            max-height: 126px;
                            object-fit: cover;
                        }
                    }
                }
                .news-content{
                    min-height: 130px;
                }
                h3.title {
                    font-size: 14px;
                    margin: 0px; 
                    font-weight: 500;
                    line-height: 23px;
                    &:first-letter {
                      text-transform: uppercase;
                    }
                  }

                ul{
                    margin: 15px 0px 0px;
                    padding: 0px;
                    li{
                        font-size: 13px;
                        font-weight: 400;
                        color: $text-40;
                        .pi{
                            font-size: 13px;
                            color: $text-30;
                            margin-right: 5px;
                        }
                        span{
                            &.author{
                                font-weight: 500;
                            }
                        }
                    }
                }
                .desc-content {
                    word-break: break-word;
                    white-space: normal;
                    cursor: pointer;
                    font-size: 13px !important;
                    font-weight: 500 !important;
                    color: $text-30 !important;
                    line-height: 22px !important;
                    margin:  6px 0px 0px !important;
                    p{
                        font-size: 13px;
                        font-weight: 500;
                        color: $text-30;
                        line-height: 22px;
                        margin: 6px 0px 0px;
                        &:first-letter {
                            text-transform: uppercase;
                          }
                    }
                }
                .desc-content > *:last-child::after {
                    content: '...see more';
                    color: $text-40;
                }
                .dropdown-popup{
                    .p-button{
                        margin: 0px;
                    }
                }
            }
        }
        .pagination{
            padding: 20px 25px;
            background: $white;
            border-radius: 0px 0px 8px 8px;
            border: 1px solid $bg-40;
            border-top: none;
            background: $bg-30;
            span{
                font-size: 13px;
                font-weight: 500;
                color: $text-30;
            }
            ul{
                padding: 0px;
                margin: 0px;
                li{
                    text-align: center;
                    line-height: 25px;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    color: $text-40;
                    &.disabled{
                        color: $text-30;
                        opacity: 0.8;
                        pointer-events: none;
                    }
                    &:hover{
                        color: $primary-40;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .news-right-section{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        margin-bottom: 25px;
        .header-section{
            padding: 14px 25px;
            border-bottom: 1px solid $bg-40;
            h3{
                font-size: 13px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 21px;
                .pi{
                    margin-right: 5px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
        .upcoming-news{
            padding: 25px;
            .news-img{
                img{
                    width: 100%;
                }
            }
            h4{
                font-size: 13px;
                font-weight: 500;
                color: $text-30;
            }
            p{
                margin: 0px;
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
                line-height: 21px;
            }
            ul{
                margin: 0px;
                padding: 0px 25px;
                li{
                    font-size: 13px;
                    font-weight: 500;
                    color: $text-40;
                    margin-bottom: 5px;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .categories-section{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        margin-bottom: 25px;
        .header-section{
            padding: 14px 25px;
            border-bottom: 1px solid $bg-40;
            h3{
                font-size: 13px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 21px;
                .pi{
                    margin-right: 5px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
        .categories-list{
            padding: 25px;
            ul{
                padding: 0px;
                margin: 0px 0px 25px;
                li{
                    font-size: 13px;
                    font-weight: 600;
                    color: $text-40;
                    &:last-child{
                        font-weight: 500;
                        color: $text-30;
                    }
                    .dot{
                        width: 7px;
                        height: 7px;
                        border-radius: 25px;
                        background: $gray-600;
                        display: inline-block;
                        margin-right: 5px;
                        &.red-color{
                            background: $primary-50;
                        }
                        &.green-color{
                            background: $success-20;
                        }
                        &.blue-color{
                            background: #529FC0; 
                        }
                        &.yellow-color{
                            background: $warning-20;
                        }
                        &.purple-color{
                            background: #C764BD;
                        }
                    }
                }
                &:last-child{
                    margin: 0px 0px 0px;
                }
            }
        }
    }
    .new-view-section{
        border-radius: 8px;
        border: 1px solid $bg-40;
        background: $white;
        margin-bottom: 25px;
        .header-section{
            padding: 14px 25px;
            border-bottom: 1px solid $bg-40;
            h3{
                font-size: 13px;
                font-weight: 600;
                color: $text-40;
                margin: 0px;
                line-height: 21px;
                .pi{
                    margin-right: 5px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
        .viewed-list{
            padding: 25px;
            .news-img{
                .p-image{
                    img{
                        max-width: 152px;
                        height: 83px;
                        object-fit: cover;
                        border-radius: 6px;
                    }
                }
            }
            .news-heading{
                h3{
                    margin: 0px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-40;
                    line-height: 23px;
                }
            }
        }
    }
    &.news-details-section{
        h1{
            font-size: 20px;
            font-weight: 500;
            color: $text-40;
            font-family: $font-secondary;
            margin: 0px 0px 20px;
        }
        .new-list-section{
            .list-wrapper{
                border-radius: 8px;
                margin-top: 0px;
                .news-list, .news-editScreen{
                    ul{
                        margin: 0px 0px 25px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 500;
                        color: $text-40;
                        line-height: 23px;
                    }
                    .attachments{
                        margin-top: 25px;
                        .heading-span{
                            display: block;
                            font-size: 13px;
                            font-weight: 500;
                            color: $text-40;
                            margin-bottom: 15px;
                        }
                        .download-button{
                            background: $bg-20;
                            padding: 8px 12px 8px 14px;
                            border-radius: 4px;
                            border: 1px solid $bg-40;
                            display: flex;
                            margin-top: 10px;
                            .p-button-label{
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                            }
                            .p-button-icon{
                                color: $text-40;
                                font-size: 12px;
                            }
                            &:hover{
                                background: $text-40;
                                .p-button-label{
                                    color: $white;
                                }
                                .p-button-icon{
                                    color: $white;
                                }
                                .pi-times{
                                    color: $white;
                                }
                            }
                            &:focus{
                                box-shadow: none;
                            }
                            .pi-times{
                                color: $text-40;
                                font-size: 12px;
                                margin-left: 10px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                    .news-img{
                        img{
                            height: auto;
                            max-height:inherit;
                        }
                    }
                    .chips-ul{
                        margin: 0px;
                        padding: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 2px;
                        li{
                            list-style: none;
                            padding: 2px 10px;
                            border-radius: 34px;
                            background: $bg-20;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 160%;
                            color: $text-40;
                        }
                    }
                }
            }
        }
        .new-view-section{
            margin-top: 0px;
        }
    }
    
}

.backbtn-outer{
    padding: 20px 70px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D1D5DB;
    .back-btn{
        color: #6E6E77;
        font-size: 13px;
        gap: 8px;
        cursor: pointer;
        .pi-arrow-left{
            font-size: 10px;
            color: #ADADB7;
        }
    }
}
.addmore-attachments{
    .addmore-button{
        background: $text-40;
        border-radius: 4px;
        border: 1px solid $text-40;
        padding: 8px 14px 8px 12px;
        .p-button-label{
            font-size: 13px;
            font-weight: 500;
            color: $white;
        }
        .p-button-icon{
            color: $white;
            font-size: 12px;
        }
        &:hover{
            background: $white;
            border-color: #D9DFE8;
            .p-button-label, .p-button-icon{
                color: $text-40;
            }
        }
        &:focus{
            box-shadow: none;
        }
    }
    .remove-button{
        background: none;
        border: none;
        padding: 0px;
        width: auto;
        margin: 0px 15px 0px 5px;
        &:hover{
            background: none;
            .p-button-icon{
                color: $danger-20 !important;
            }
        }
        &:focus{
            box-shadow: none;
        }
    }
}