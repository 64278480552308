@import '../../assets/scss/variables.scss';

.main{
    margin: 0px;
    background: $white;
    .alert-details-section{
        padding: 25px 70px;
        height: calc(100% - 204px);
        min-height: calc(100vh - 204px);
        h1{
            font-size: 24px;
            font-weight: 500;
            margin: 0rem 0rem 1.6rem;
            font-family: $font-secondary;
        }
        .details-wrapper{
            border-radius: 6px;
            border: 1px solid $bg-40;
            background: $white;
            .header-section{
                border-bottom: 1px solid $bg-40;
                padding: 20px 20px 13px;
                h2{
                    font-size: 18px;
                    font-weight: 600;
                    color: $text-40;
                    font-family: $font-secondary;
                    margin: 0px;
                }
                .icon{
                    line-height: 0px;
                }
                .respond-button{
                    background: $primary-60;
                    border-radius: 4px;
                    border: none;
                    padding: 8px 14px;
                    .p-button-label{
                        font-size: 13px;
                        font-weight: 500;
                        color: $white;
                    }
                    &:hover{
                        background: $text-40;
                    }
                    &:focus{
                        box-shadow: none;
                    }
                    &.status-button{
                        background: $blue-70;
                        &.member{
                            background: $primary-60;
                        }
                        &:hover{
                            background: $text-40;
                        }
                    }
                    &.assign-button{
                        padding: 8px 14px;
                        border-radius: 4px;
                        border: 1px solid $border-gray;
                        background:$white;
                        .p-button-label{
                            font-size: 13px;
                            font-weight: 500;
                            color: $text-40;
                        }
                        &:hover{
                            background: $text-40;
                            .p-button-label{
                                color: $white;
                            }
                        }
                    }
                }
                .left-section{
                    width: 100%;
                    max-width: 72%;
                    .sports-icon{
                        line-height: 0px;
                        .p-image{
                            line-height: 0px;
                            display: block;
                            border-radius: 20px;
                        }
                        img{
                            width: 30px;
                            border-radius: 20px;
                        }
                    }
                }

            }
            .details-section{
                border-radius: 8px;
                border: 1px solid #E7EBE9;
                background: $bg-20;
                padding: 20px;
                margin: 20px;
              
                .response-editorheader{
                    border-bottom: 1px solid #DBE1DC;
                    margin-bottom: 1rem;
                    padding-bottom: 1rem;
                }
                h3{
                    margin: 0px;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-40;
                }
                .details-ul{
                    margin: 0px 0px 18px;
                    padding: 0px;
                    display: flex;
                    li{
                        list-style: none;
                        display: inline-block;
                        font-size: 13px;
                        color: $text-40;
                        &:first-child{
                            width: 150px;
                        }
                        &:last-child{
                            font-weight: 500;
                        }
                        .download-button {
                            padding: 8px 10px 8px 8px;
                            border-radius: 6px;
                            border: 1px solid $border-gray;
                            background:$white;
                            margin-right: 10px;
                            margin-bottom: 8px;
                            text-align: left;
                            width: 25rem;
                            .p-button-label {
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                            }
                            .p-button-icon {
                                color: $text-40;
                                font-size: 12px;
                            }
                        }
                    }
                }
                .border-right{
                    border-right: 1px solid #E6EAE7;
                }
                .data-table{
                    margin: 20px 0px;
                    .p-datatable-wrapper{
                        border-radius: 6px;
                        border: 1px solid $border-gray;
                        border-bottom: 0px;
                        background: $white;
                        .p-datatable-table{
                            .p-datatable-thead{
                                .p-column-header-content{
                                    .p-column-title{
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: $gray-600;
                                    }
                                    span{
                                        line-height: 0px;
                                    }
                                }
                            }
                            .p-datatable-tbody{
                                tr{
                                    background: $white;
                                    td{
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: $gray-900;
                                        padding: 10px 14px;
                                        strong{
                                            display: block;
                                        }
                                    }
                                    &.p-row-odd{
                                        background: $white;
                                    }
                                }
                            }
                        }
                    }
                }
                .box{
                    padding: 14px;
                    border-radius: 6px;
                    border: 1px solid $bg-40;
                    background: $white;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    min-height: 120px;
                    height: 100%;
                    p{
                        margin: 0px;
                        font-size: 12px;
                        font-weight: 400;
                        color: $text-30;
                        padding: 0px 10px;
                        line-height: 20px;
                        span{
                            font-weight: 500;
                            color: $text-40;
                        }
                    }
                }
                &.advice-report-section{
                    border-radius: 8px;
                    border: 1px solid $bg-20;
                    background: $bg-30;
                    .advice-report-block{
                        padding-right: 2rem;
                        p{
                            margin: 0px;
                            color: $text-60;
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 160%;
                            padding-bottom: 1.5rem;
                            text-align: justify;
                            &:last-child{
                                padding-bottom: 0rem;
                            }
                        }
                    }
                    .attached-files-block{
                        padding-left: 1.8rem;
                        p{
                            margin: 0rem 0rem 1.5rem;
                            color: $text-60;
                            font-family: Inter;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 160%;
                        }
                        .download-button{
                            padding: 6px 16px 6px 14px;
                            border-radius: 4px;
                            border: 1px solid $border-gray;
                            background:$white;
                            margin-bottom: 12px;
                            display: flex;
                            flex-direction: row;
                            .p-button-label{
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                            }
                            .p-button-icon{
                                color: $text-40;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .description-comment-section{
                margin: 20px;
                .p-accordion{
                    position: relative;
                    .p-accordion-tab{
                        margin-bottom: 0px;
                        .p-accordion-header{
                            border-radius: 8px;
                            .p-accordion-header-link{
                                border: 1px solid #E7EBE9;
                                background: $bg-20;
                                padding: 20px;
                                &:focus{
                                    box-shadow: none;
                                }
                                .p-accordion-header-text{
                                    font-size: 15px;
                                    font-weight: 500;
                                    color: $text-40;
                                    width: 100%;
                                }
                                .p-accordion-toggle-icon{
                                    position: absolute;
                                    right: 10px;
                                }
                            }
                            .edit-button{
                                position: absolute;
                                right: 55px;
                                z-index: 1;
                            }
                            p{
                                margin: 0px;
                            }
                        }
                        .p-toggleable-content{
                            .p-accordion-content{
                                background: $bg-20;
                                border: 1px solid #E7EBE9;
                                border-radius: 0px 0px 8px 8px;
                                p{
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 22px;
                                    color: $text-60;
                                    margin: 0px 0px 20px;
                                    &.desc-ws {
                                        white-space: pre-line;
                                    }
                                }
                                .comment-panel{
                                    .chat-messages{
                                        height: 500px;
                                        overflow: auto;
                                        padding-right: 10px;
                                        &::-webkit-scrollbar {
                                            width:6px;
                                        }
                                        &::-webkit-scrollbar-track {
                                            background: $bg-20;
                                            border-radius: 10px;
                                        }
                                        &::-webkit-scrollbar-thumb {
                                            background: $bg-40;
                                            border-radius: 10px;
                                        }
                                        &::-webkit-scrollbar-thumb:hover {
                                            background: $text-20; 
                                        }
                                    }
                                    .message-box{
                                        position: relative;
                                        .sender-name{
                                            p{
                                                margin: 0px;
                                                span{
                                                    font-size: 13px;
                                                    font-weight: 500;
                                                    color: $text-40;
                                                }
                                            }
                                        }
                                        .message{
                                            border-radius: 0px 12px 12px 12px;
                                            background: #e5e5e5;
                                            padding: 16px;
                                            // margin-top: 10px;
                                            width: 100%;
                                            p{
                                                margin: 0px;
                                                font-size: 13px;
                                                font-weight: 500;
                                                color: $text-40;
                                                word-break: break-word;
                                                text-align: justify;
                                            }
                                        }
                                        .time{
                                            span{
                                                font-size: 12px;
                                                font-weight: 400;
                                                color: $text-40;
                                                margin-top: 5px;
                                            }
                                        }
                                        .edit-delete-popup{
                                            display: none;
                                            position: absolute;
                                            top: -2px;
                                            right: 5px;
                                            align-items: center;
                                            gap:5px;
                                            background: $white;
                                            padding: 4px 8px;
                                            border-radius: 5px;
                                            z-index: 999;
                                            .pi{
                                                font-size: 15px;
                                                padding: 4px;
                                                border-radius: 10px;
                                                &:hover{
                                                    color: $blue-70;
                                                    background: $bg-20;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                        &:hover{
                                            .edit-delete-popup{
                                                display: flex;
                                            } 
                                        }
                                        &:first-child{
                                            margin-top: 0.8rem;
                                        }
                                    }
                                    .comment-box{
                                        position: relative;
                                        margin-top: 1rem;
                                        .p-inputtext{
                                            width: 100%;
                                            border-radius: 6px;
                                            border: 1px solid $bg-40;
                                            background: $white;
                                            font-size: 14px;
                                            font-weight: 400;
                                            resize: none;
                                        }
                                        .send-button{
                                            padding: 8px 14px;
                                            border-radius: 4px;
                                            border:none;
                                            background:$primary-60;
                                            .p-button-label{
                                                font-size: 13px;
                                                font-weight: 500;
                                                color: $white;
                                            }
                                            &:hover{
                                                background: $blue-70;
                                            }
                                            &.cancel-button{
                                                margin-right: 10px;
                                                background: $white;
                                                border: 1px solid $text-40;
                                                padding: 7px 14px;
                                                .p-button-label{
                                                    color: $text-40;
                                                }
                                                &:hover{
                                                    background: $danger-20;
                                                    border: 1px solid $danger-20;
                                                    .p-button-label{
                                                        color: $white;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .responses-section{
                background: $white;
                padding: 0px 20px 20px;
                .left-section{
                    border-radius: 6px;
                    border: 1px solid $bg-40;
                    background: $white;
                    padding: 20px;
                    h3{
                        margin: 0px;
                        font-size: 15px;
                        font-weight: 500;
                        color: $text-40;
                    }
                    .no-response-p-tag{
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 140%;
                        color: $text-40;
                    }
                    .response-button{
                        padding: 6px 10px 6px 8px;
                        border-radius: 4px;
                        border: 1px solid $border-gray;
                        background:$white;
                        .p-button-label{
                            font-size: 13px;
                            font-weight: 500;
                            color: $text-40;
                            margin-left: 5px;
                        }
                        &:hover{
                            background: $text-40;
                            border: 1px solid $text-40;
                            .p-button-label{
                                color: $white;
                            }
                        }
                    }
                    .filters-list{
                        margin:25px 0px 25px;
                        .status-box{
                            background: $bg-20;
                            padding: 6px 12px 6px 8px;
                            border-radius: 4px;
                            border: 1px solid $bg-40;
                            .p-checkbox{
                                width: 18px;
                                height: 18px;
                                .p-checkbox-box{
                                    width: 18px;
                                    height: 18px;
                                    background: #F3F3F3;
                                    border-radius: 4px;
                                    &:hover{
                                        border-color: $text-40;
                                    }
                                    &.p-focus{
                                        box-shadow: none;
                                    }
                                }
                                &.p-checkbox-checked{
                                    .p-checkbox-box{
                                        background:$text-40;
                                        border-color: $text-40;
                                    }
                                }
                            }
                            label{
                                font-size: 13px;
                                font-weight: 500;
                                color: $text-40;
                            }
                        }
                    }
                    .response-accordion{
                        position: relative;
                        .p-accordion-tab{
                            margin-bottom: 0px;
                            .p-accordion-header{
                                border-radius: 8px;
                                .p-accordion-header-link{
                                    border: 1px solid #E7EBE9;
                                    background: $bg-20;
                                    padding: 16px 20px;
                                    &:focus{
                                        box-shadow: none;
                                    }
                                    .p-accordion-header-text{
                                        font-size: 15px;
                                        font-weight: 500;
                                        color: $text-40;
                                        width: 100%;
                                        ul{
                                            margin: 0px;
                                            padding: 0px;
                                            li{
                                                color: $text-30;
                                                font-size: 12px;
                                                font-weight: 400;
                                                .icon{
                                                    line-height: 0px;
                                                    img{
                                                        width: 26px;
                                                        height: 26px;
                                                    }
                                                }
                                                span{
                                                    color: $text-40;
                                                    font-size: 13px;
                                                    font-weight: 500;
                                                }
                                            }
                                        }
                                        .response-options{
                                            margin-right: 35px;
                                            .response-chat{
                                                margin-left: 14px;
                                                position: relative;
                                                img{
                                                    margin-left: -14px;
                                                    cursor: pointer!important;
                                                }
                                                &.active::after{
                                                    position: absolute;
                                                    content: '';
                                                    width: 6px;
                                                    height: 6px;
                                                    background: $danger-20;
                                                    border-radius: 5px;
                                                    right: -2px;
                                                }
                                            }
                                            .status{
                                                font-size: 12px;
                                                font-weight: 500;
                                                padding: 3px 8px;
                                                align-items: center;
                                                border-radius: 16px;
                                                display: inline-flex;
                                                border: 1.5px solid $text-30;
                                                &.error{
                                                    border: 1.5px solid $danger-20;
                                                    color: $danger-20;
                                                }
                                                &.warning{
                                                    border: 1.5px solid $warning-20;
                                                    color: $warning-20;
                                                }
                                                &.success{
                                                    border: 1.5px solid $success-20;
                                                    color: $success-20;
                                                }
                                                &.not-started{
                                                    border: 1.5px solid $gray-700;
                                                    color: $gray-700;
                                                }
                                            }
                                        }
                                    }
                                    .p-accordion-toggle-icon{
                                        position: absolute;
                                        right: 10px;
                                    }
                                }
                            }
                            .p-toggleable-content{
                                .p-accordion-content{
                                    background: $bg-20;
                                    border: 1px solid #E7EBE9;
                                    border-radius: 0px 0px 8px 8px;
                                    p{
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 22px;
                                        color: $text-60;
                                        margin: 0px 0px 20px;
                                    }
                                    .details-section{
                                        border-radius: 8px;
                                        border: none;
                                        background: none;
                                        padding: 0px;
                                        margin: 0px 0px 15px;
                                        .box{
                                            p{
                                                margin: 0px;
                                                font-size: 12px;
                                                font-weight: 400;
                                                color: $text-30;
                                                padding: 0px 10px;
                                                line-height: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .right-section{
                    border-radius: 8px;
                    background: $bg-20;
                    padding: 20px;
                    height: 100%;
                    .reporter-profile{
                        .p-image{
                            img{
                                border-radius: 30px;
                                width: 46px;
                                height: 46px;
                            }
                        }
                        p{
                            font-size: 12px;
                            font-weight: 400;
                            color: $text-40;
                            margin: 6px 0px;
                        }
                        h4{
                            margin: 0px;
                            font-size: 13px;
                            font-weight: 500;
                            color: $text-40;
                        }
                    }
                    ul{
                        margin: 0px 0px 16px;
                        padding: 0px;
                        li{
                            font-size: 12px;
                            font-weight: 400;
                            color: $text-40;
                            &:last-child{
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}
.files-download-ul{
    li:first-child{
        width: auto !important;
    }
    .file-download{
        padding: 8px 10px 8px 8px;
        border-radius: 6px;
        border: 1px solid $border-gray;
        background:$white;
        margin-bottom: 8px;
        text-align: left;
        .p-button-label {
            font-size: 12px;
            font-weight: 500;
            color: $text-40;
        }
        .p-button-icon {
            color: $text-40;
            font-size: 12px;
        }
        &:hover{
            background: $text-40;
            .p-button-label {
                color: $white;
            }
            .p-button-icon {
                color: $white;
            }
        }
    }
}
.description-dialog{
    width: 100%;
    max-width: 500px;
    .p-inputtextarea{
        margin-top: 10px;
        border-radius: 6px;
        border: 1px solid $bg-40;
        background: $bg-20;
        font-size: 14px;
        font-weight: 500;
        color: $text-60;
        padding: 10px 14px;
        width: 100%;
        resize: none;
        min-height: 345px;
        line-height: 23px;
        &::-webkit-scrollbar {
            width:6px;
        }
        &::-webkit-scrollbar-track {
            background: $bg-20;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $bg-40;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $text-20; 
        }
        &:focus{
            box-shadow: none;
            border-color: $text-20;
        }
    }
}
.response-alert-dialog{
    width: 100%;
    max-width: 365px;
    h2{
        margin-top: 18px !important;
        line-height: 30px;
    }
    .box{
        background: $bg-20;
        padding: 8px 14px;
        border-radius: 6px;
        width: 100%;
        .p-radiobutton{
            width: 18px;
            height: 18px;
            .p-radiobutton-box{
                border: 1px solid $bg-40;
                width: 18px;
                height: 18px;
            }
            &.p-radiobutton-checked{
                .p-radiobutton-box{
                    background: $bg-20;
                    border: 1px solid $primary-70;
                }
                .p-radiobutton-icon{
                    width: 8px;
                    height: 8px;
                    background: $primary-70;
                }
            }
        }
    }
    .add-comment{
        margin-top: 10px;
        border-radius: 6px;
        border: 1px solid $bg-40;
        background: $bg-20;
        font-size: 14px;
        font-weight: 500;
        color: $text-60;
        padding: 10px 14px;
        width: 100%;
        resize: none;
        min-height: 200px;
        line-height: 23px;
    }
}
.file-upload{
    border-radius: 4px;
    border: 1px dashed #C6C8D1;
    background: $bg-20;
    padding: 6px 7px;
    .p-fileupload-buttonbar{
        padding: 0rem;
        border: none;
    }
    .p-fileupload-content{
        display: none;
    }
    .p-fileupload .p-fileupload-buttonbar .p-button{
        margin: 0px;
    }
    .p-fileupload-choose{
        padding: 4px 12px;
        background: $white;
        border: 1px solid #EAECF0;
        white-space: nowrap;
        .p-button-label{
            font-size: 13px;
            font-weight: 500;
            color: $text-40;
        }
        svg{
            color: $text-40;
            width: 10px;
            height: 10px;
        }
    }
    span{
        font-size: 12px;
        font-weight: 400;
        color:#9295A3;
    }
}
.uploaded-file-container{
    padding: 4px 0px;
    .uploaded-files{
        padding: 4px 10px 4px 8px;
        border-radius: 4px;
        border: 1px solid #EAECF0;
        color: #151518;
        font-size: 13px;
        font-weight: 500;
        width: fit-content;
        .pi.pi-trash {
            margin-left: 6px;
            color: #9295A3;
            font-size: 12px;
            cursor: pointer;
            &:hover{
                color: $danger-20;
            }
        }
        &.new-file{
            border-color: $blue-70;
        }
    }
}
.edit-alert-dialog{
    width: 100%;
    max-width: 800px;
    .p-dialog-content{
        &::-webkit-scrollbar {
            width:6px;
        }
        &::-webkit-scrollbar-track {
            background: $bg-20;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $bg-40;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $text-20; 
        }
        .create-alert-section{
            padding: 0px;
            height: calc(100% - 500px);
            min-height: calc(100vh - 500px);
            h1{
                font-size: 20px;
                font-weight: 500;
                color: $text-40;
            }
            p{
                font-size: 14px;
                font-weight: 400;
                color: $text-40;
            }
            .create-alert-wrapper{
                width: 100%;
                margin: auto;
                .form-section{
                    box-shadow: none;
                    border: none;
                    .form-body{
                        padding: 0px;
                    }
                }
            }
        }
    }
}

.response{
    border-radius: 16px;
    border: 1px solid $bg-40;
    font-weight: 500;
    color: $text-40;
    padding: 5px 0px 0px 7px;
    cursor: pointer;
    .pi{
        font-size: 10px;
        color:$text-30
    }
    &.chat{
        position: relative;
        width: 30px;
        height: 30px;
        display: flex;
    }
    &.active::after{
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        background: $danger-20;
        border-radius: 5px;
        right: -1px;
        top: 2px;
    }
    &.disabled{
        opacity: 0.5;
        pointer-events: none;
        .text-blur{
            filter: blur(2px);
        }
    }
}

.tagSection {
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #D1DCD3;
    background: #ffffff;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 5px;
}
.alert-status-dialog{
    max-width: 425px !important;
    .dialog-body-section{
        padding-bottom: 1rem;
        .status {
            font-size: 13px;
            font-weight: 500;
            padding: 2px 8px 4px;
            align-items: center;
            border-radius: 16px;
            display: inline-flex;
            border: 1.5px solid #6E6E77;
        }
        .status-input{
            border-radius: 4px !important;
            padding: 10px 14px !important;
            text-align: center;
            background: $blue-bg-20 !important;
            border: 1px solid #ACBACA !important;
            color: $text-40 !important;
        }
        .multi-select-dropdown{
            padding: 3px 2px;
            background: $blue-bg-20 !important;
            border: 1px solid #ACBACA !important;
            max-width: 220px;
            .p-dropdown-label{
                font-family: $font-base;
                font-size: 13px;
                font-weight: 500;
                line-height: 160%;
            }
        }
        .assignee-flex{
            display: grid;
            grid-template-columns: auto auto;
            grid-auto-flow: column;
            justify-content: center;
            .status{
                display: inline-block;
                text-align: center;
            }
        }
    }
}
.evaluating-status-dialog{
    max-width: 800px !important;
    .dialog-body-section{
        .role-grid{
            display: grid;
            grid-template-columns: auto auto auto;
            grid-auto-flow: column;
            text-align: center;
            margin: 0px;
        }
        .multi-select-dropdown{
            padding: 3px 2px;
            background: $blue-bg-20 !important;
            border: 1px solid #ACBACA !important;
            .p-dropdown-label{
                font-family: $font-base;
                font-size: 13px;
                font-weight: 500;
                line-height: 160%;
                color: $text-40;
                padding: 6px 12px !important;
            }
        }
        .detail-textarea{
            border-radius: 6px;
            border: 1px solid $blue-bg-40 !important;
            background: $blue-bg-20 !important;
            width: 100%;
            resize: none;
            padding: 10px 14px;
            border-radius: 6px;
            min-height: 225px;
            font-size: 13px;
            color: #6E6E77;
            font-weight: 400;
        }
        .create-file-upload {
            border-radius: 4px;
            border: 1px dashed $blue-bg-40;
            background: $blue-bg-20;
            padding: 6px 7px;
            .p-fileupload-buttonbar {
              padding: 0rem;
              border: none;
            }
            .p-fileupload-content {
              display: none;
            }
            .p-fileupload .p-fileupload-buttonbar .p-button {
              margin: 0px;
            }
            .p-fileupload-choose {
              padding: 4px 12px;
              background: $white;
              border: 1px solid #eaecf0;
              white-space: nowrap;
              .p-button-label {
                font-size: 13px;
                font-weight: 500;
                color: $text-40;
              }
              svg {
                color: $text-40;
                width: 10px;
                height: 10px;
              }
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #9295a3;
            }
        }
        .tag-select{
            border-radius: 4px;
            border: 1px solid $blue-bg-40 !important;
            background: $blue-bg-20 !important;
            min-height: 40px;
            .p-multiselect-label{
                font-family: $font-base;
                font-size: 13px;
                font-weight: 500;
                .p-multiselect-token{
                    border-radius: 4px;
                    border: 1px solid #CFD8E3;
                    background:$white;
                    padding: 5px 6px 5px 8px;
                    .p-chips-token-label{
                        font-family: $font-base;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 160%;
                        color: $text-40;
                    }
                    .p-chips-token-icon{
                        opacity: 0.6;
                        position: relative;
                        top: 1px;
                        left: 5px;
                        margin-left: 5px;
                        &:hover{
                            color: $primary-70;
                            opacity: 1;
                        }
                    }

                }
            }
        }
    }
}
.attach-fileupload{
    .p-fileupload-choose{
        &:hover{
            background: $white !important;
            border: 1px solid #EAECF0 !important;
        }
    }
}
//to remove the default style when accordion = disabled
.p-accordion-header{
    &.p-disabled {
        opacity: 1!important;
    } 
    &.p-disabled .p-icon.p-accordion-toggle-icon{
        opacity: 0;
    }
} 

.p-accordion.response-accordion {
    .p-disabled, .p-disabled * {
        pointer-events: auto!important;
        cursor: auto!important;
    }
}
.descriptioncharLimit {
    font-size: 12px;
    text-align: right;
}



.dialog-logout-btns{
    padding: 8px 14px;
    border-radius: 4px;
    border: 1px solid $bg-40;
    background: $white;
    .p-button-label{
        font-size: 13px;
        font-weight: 500;
        color: $text-40;
    }
    &.send-message{
        background: $primary-60;
        border-color: $primary-60;
        .p-button-label{
            color: $white;
        }
        &:hover{
            background: $text-40 !important;
            border-color: $text-40 !important;
        }
    }
    &:focus{
        box-shadow: none;
    }
}

.customerGrid{
    display: grid;
    grid-template-columns: max-content max-content  minmax(130px, max-content);
    grid-gap:16px;
    margin-bottom: 18px;
    .details-ul{
        margin: 0px 0px 0px !important;
    }
}
@media (min-width: 1280px) and (max-width: 1650px) {
    .alert-response-grid{
        .p-datatable-wrapper{
            overflow: auto;
        }
        .p-datatable-table{
            width: 1200px;
        }
    
    }
}
@media (min-width: 1280px) and (max-width: 1650px) {
    .alert-response-grid{
        .p-datatable-wrapper{
            overflow: auto;
        }
        .p-datatable-table{
            width: 1200px;
        }
    
    }
}
.edit-button{
    padding: 6px 10px 6px 8px;
    border-radius: 4px;
    border: 1px solid $border-gray;
    background:$white;
    .label{
        font-size: 13px;
        font-weight: 500;
        color: $text-40;
        margin-left: 5px;
    }
    .p-image{
        line-height: 0px;
        img{
            position: relative;
            top: 1px;
        }
    }
    &:hover{
        background: $text-40 !important;
        border: 1px solid $text-40 !important;
        .label{
            color: $white;
        }
        .p-image{
            img{
                filter: brightness(0) invert(1);
            }
        }
    }
}
.dropdown-popup {
    .info-button {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #DEDEDE;
        background: #ffffff;
        width: 32px;
        .p-button-icon{
            color: $text-40;
        }
        &:hover{
            background: $blue-70;
            .p-button-icon{
                color: $white;
            }
        }
    }
}
.custom-textarea {
    width: 100% !important;
    min-height: 60px !important; /* Enforce minimum height */
    max-height: 350px !important; /* Enforce maximum height */
    overflow-y: auto !important; /* Enable vertical scrolling */
    font-size: 14px !important;
    resize: none !important; /* Prevent resizing */
    text-align: justify !important;
    box-sizing: border-box !important; /* Include padding and borders in dimensions */
  }
.responseTabBorder{
    border: 1px solid #bed4ff;
}